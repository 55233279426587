@import "../../styles/_style";
$backgroundImageUrl: "../../images/contact_form_logo.png";

.contact-form__form-container .form .industry {
  &__title {
    /* Box-model */
    margin-bottom: 17px;

    &::after {
      /* Typography */
      color: $red-text;

      /* Visual */
      content: " *";
    }
  }

  &__container {
    /* Box-model */
    display: flex;
    height: 222px;

    /* Flexible Box */
    flex-direction: column;
    flex-wrap: wrap;
  }

  &__item {
    /* Positioning */
    position: relative;

    /* Box-model */
    margin-bottom: 22px;

    & label {
      /* Box-model */
      display: flex;

      /* Typography */
      cursor: pointer;

      & input[type="radio"] {
        /* Visual */
        opacity: 0;

        &:checked {
          &+.industry__item--name {
            /* Typography */
            color: inherit;

            &::before {
              /* Visual */
              background: $gray-contact-us-radio;
              opacity: 1;
            }

            &::after {
              /* Positioning */
              position: absolute;
              top: 1px;
              left: 3px;

              /* Box-model */
              display: inline-block;
              width: 12px;
              height: 12px;

              /* Visual */
              border-radius: 50%;
              border: 1px solid $gray-contact-us-radio;
              content: "";
              opacity: 0.2;
              box-sizing: border-box;
            }
          }
        }
      }
    }

    &--name {
      /* Typography */
      color: $gray-contact-us-button;

      &::before {
        /* Positioning */
        position: absolute;
        top: 3px;
        left: 5px;

        /* Box-model */
        display: inline-block;
        width: 8px;
        height: 8px;

        /* Visual */
        border-radius: 50%;
        border: 1px solid $gray-contact-us-radio;
        content: "";
        opacity: 0.2;
        box-sizing: border-box;
      }
    }
  }
}

@media (max-width: 1365px) {
  .contact-form__form-container .form .industry {
    /* Box-model */
    margin: 10.6vw 0; /// 34/320 = 0.10625

    &__title {
      /* Box-model */
      margin-bottom: 5.3vw; /// 17/320 = 0.053125

      /* Typography */
      font-size: 4.4vw; /// 14/320 = 0.04375
    }

    &__container {
      /* Box-model */
      display: grid;
      height: unset;

      /* Grid box */
      grid-template-columns: repeat(2, 1fr);
    }

    &__item {
      /* Box-model */
      margin-bottom: 0;

      /* Typography */
      font-size: 4.4vw; /// 14/320 = 0.04375
      font-weight: normal;
      line-height: 9.4vw; /// 30/320 = 0.09375

      & label {
        & input[type="radio"] {
          /* Box-model */
          display: none;

          &:checked {
            &+.industry__item--name {
              &::before {
                /* Visual */
                background: unset;
                opacity: .2;
              }

              &::after {
                /* Positioning */
                top: 50%;
                left: .66vw;

                /* Box-model */
                width: 2.5vw; /// 12/320 = 0.0375
                height: 2.5vw; /// 12/320 = 0.0375

                /* Visual */
                background: $gray-contact-us-radio;
                border: .3vw solid $gray-contact-us-radio;
                transform: translateY(-50%);
                opacity: 1;
              }
            }
          }
        }
      }

      &--name {
        /* Positioning */
        position: relative;

        /* Box-model */
        display: flex;

        /* Flexible box */
        align-items: center;

        &::before {
          /* Positioning */
          position: relative;
          top: 0;
          left: 0;

          /* Box-model */
          width: 3.8vw; /// 8/320 = 0.025
          height: 3.8vw; /// 8/320 = 0.025
          margin-right: 2vw;

          /* Visual */
          border: .3vw solid $gray-contact-us-radio;
        }
      }
    }
  }
}
