header.header {
  /* Box-model */
  margin-top: 0px;
}

@media (max-width: 1365px) {
  header.header {
    /* Positioning */
    position: absolute;
    left: 0;
  }

  header.header-image {
    /* Positioning */
    position: relative;
    top: 20vw;

    /* Box-model */
    width: 100vw;

    & img {
      /* Box-model */
      width: 100%;
    }

    &__peer {
      /* Positioning */
      top: 0;

      /* Box-model */
      width: 100vw;

      & img {
        /* Box-model */
        width: 100%;
      }
    }
  }
}
