@import "../../styles/_style";

.arisan-contact-information {
  /* Box-model */
  width: 149px;

  & a {
    /* Typography */
    text-decoration: none;

    /* Visual */
    user-select: none;
  }

  &__logo {
    /* Box-model */
    margin-bottom: 21px;

    &>img {
      /* Box-model */
      width: 80px;
    }
  }

  &__contact-information {
    &--red {
      /* Typography */
      font-size: 14px;

      &>a {
        /* Typography */
        color: $red-text;
      }

      &:nth-child(1) {
        /* Box-model */
        margin-bottom: 2px;
      }

      &:nth-child(2) {
        /* Box-model */
        margin-bottom: 6px;
      }
    }

    &--gray {
      /* Box-model */
      margin-bottom: 7px;

      /* Typography */
      font-size: 9px;
      letter-spacing: 0;
      color: $gray-contact-us-address;
    }
  }
}

@media (max-width: 1365px) {
  .arisan-contact-information {
    /* Box-model */
    width: 100%;
    margin-top: 19.1vw; /// 61/320 = 0.190625
    margin-left: 6.3vw; /// 20/320 = 0.0625

    &__logo {
      /* Box-model */
      width: 39.1vw; /// 125/320 = 0.390625
      height: 6.9vw; /// 22/320 = 0.06875
      margin-bottom: 10vw; /// 32/320 = 0.1

      img {
        /* Box-model */
        width: 100%;
        height: 100%;
      }
    }

    &__contact-information {
      &--red {
        &:nth-child(1) {
          /* Typography */
          font-size: 6.6vw; /// 21/320 = 0.065625
          line-height: 9.4vw; /// 30/320 = 0.09375
        }

        &:nth-child(2) {
          /* Typography */
          font-size: 6.9vw; /// 22/320 = 0.06875
          line-height: 10.3vw; /// 33/320 = 0.103125
        }
      }

      &--gray {
        /* Typography */
        font-size: 4.1vw; /// 13/320 = 0.040625
        line-height: 5.6vw; /// 18/320 = 0.05625
      }
    }
  }
}
