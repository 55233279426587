@import "../../styles/_style";

.form__content .custom-info {
  /* Positioning */
  position: relative;

  /* Box-model */
  margin-bottom: 11px;

  & input {
    /* Box-model */
    width: 100%;
    height: 37px;
    padding: 6px 9px;

    /*Typography */
    color: $black-text;

    /* Visual */
    border-radius: 4.5px;

    &:focus {
      /* Visual */
      outline: none;
    }
  }

  & #country {
    /* Box-model */
    width: 100%;

    /* Visual */
    box-sizing: border-box;
    user-select: none;

    &:focus {
      /* Visual */
      border: 0px solid $gray-content;
      background-color: $gray-arisan-info;
      box-shadow: 0px 0px 0px;
    }

    &:hover {
      /* Typography */
      cursor: pointer;
    }
  }

  &__item-name {
    /* Box-model */
    margin-bottom: 3px;

    /* Typography */
    letter-spacing: 0.3px;

    &.required::after {
      /* Typography */
      color: #e60012;

      /* Visual */
      content: " *";
    }
  }

  & .country {
    /* Positioning */
    position: absolute;

    /* Box-model */
    width: 100%;

    /* Visual */
    background: $input-focus-color;
    border-radius: 4.5px;
    outline: none;
    box-sizing: border-box;

    &:hover {
      /* Typography */
      cursor: pointer;
    }

    &.show {
      /* Box-model */
      height: 347px;

      /* Visual */
      border: 1px solid $gray-content;
    }

    &__title {
      /* Typography */
      white-space: nowrap;
    }

    &__dropdown-arrow {
      /* Positioning */
      position: absolute;
      top: 22px;
      right: 15px;

      &:hover {
        /* Typography */
        cursor: pointer;
      }

      &::before {
        /// use to build dropdown arrow
        /* Visual */
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid #232323;
        content: "";
      }
    }

    &__dropdown-container {
      /* Box-model */
      height: 310px;
      overflow-y: auto;

      &--item {
        /* Box-model */
        display: flex;
        height: 37px;
        padding: 0px 9px;
        overflow-y: auto;

        /* Flexible Box */
        align-items: center;

        /* Typography */
        font-family: arisan-font-normal;

        &:hover {
          /* Visual */
          background: $gray-dropdown;
        }

        &.active {
          /* Visual */
          background: $gray-dropdown;
        }
      }
    }
  }
}


@media (max-width: 1365px) {
  .form__content {
    .custom-info {
      /* Positioning */
      position: unset;

      /* Box-model */
      margin-bottom: 3.4vw; /// 11/320 = 0.034375

      & input {
        /* Box-model */
        height: 11.6vw; /// 37/320 = 0.115625
        padding: 1.9vw 2.8vw; /// 6/320 = 0.01875, 9/320 = 0.028125

        /* Visual */
        border-radius: 1.3vw; /// 4/320 = 0.0125

        /* Typography */
        font-size: 3.4vw; /// 11/320 = 0.034375
      }

      &__item {
        /* Box-model */
        margin-bottom: .9vw; /// 3/320 = 0.009375
      }

      &__required {
        /* Typography */
        font-size: 4.4vw; /// 14/320 = 0.04375

        span {
          /* Typography */
          color: $red-text;
        }
      }

      &__item-name {
        /* Box-model */
        margin-bottom: .9vw; /// 3/320 = 0.009375

        /* Typography */
        font-size: 4.4vw; /// 14/320 = 0.04375
      }

      & .country {
        /* Positioning */
        position: relative;

        /* Visual */
        border-radius: 1.3vw; /// 4/320 = 0.0125

        &.show {
          /* Box-model */
          height: 108.4vw; /// 347/320 = 1.084375
        }

        &__dropdown-arrow {
          /* Positioning */
          top: 6.9vw; /// 22/320 = 0.06875
          right: 4.7vw; /// 15/320 = 0.046875

          &::before {
            border-top: 1.9vw solid #232323;
            border-right: 1.9vw solid transparent;
            border-left: 1.9vw solid transparent; /// 6/320 = 0.01875
          }
        }

        &__dropdown-container {
          /* Box-model */
          height: 96.9vw; /// 310/320 = 0.96875
          padding: 0px 2.8vw; /// 9/320 = 0.028125

          &--item {
            /* Box-model */
            height: 11.6vw; /// 37/320 = 0.115625

            /* Typography */
            font-size: 3.4vw; /// 11/320 = 0.034375
          }
        }
      }
    }
  }
}
