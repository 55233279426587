@import "../../styles/style";

.peer {
  & .peer-series {
    /* Box-model */
    display: flex;
    width: 1033px;
    height: 689px;
    margin-top: 948px;

    /* Flexible Box */
    justify-content: space-between;
    align-items: center;

    &__content {
      /* Box-model */
      width: 405px;
      height: 380px;

      /* Typography */
      font-size: 16px;
      line-height: 25px;
      white-space: pre-line;
    }
  }

  & .key-features {
    /* Box-model */
    width: 851px;
    height: 943px;

    &__content {
      /* Box-model */
      display: flex;

      /* Flexible Box */
      justify-content: space-between;
    }
  }

  & .overview {
    /* Box-model */
    width: 230px;

    /* Typography */
    white-space: pre-line;

    &__image {
      /* Box-model */
      width: 100%;
      height: 118px;
    }

    &__title {
      /* Box-model */
      width: 100%;
      margin-top: 24px;

      /* Typography */
      font-family: arisan-font-bold;
      font-size: 21px;
      line-height: 26px;
      color: $purple-peer;
      text-align: center;
    }

    &__subTitle {
      /* Box-model */
      width: 100%;

      /* Typography */
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      color: $purple-peer;
    }

    &__description {
      /* Box-model */
      width: 100%;
      margin-top: 24px;

      /* Typography */
      font-size: 16px;
      line-height: 25px;
      letter-spacing: 0.3px;
    }
  }

  & .infrastructure {
    /* Box-model */
    width: 1280px;
    height: 1324px;

    &__video {
      &--horizontal {
        /* Box-model */
        display: block;
      }
      
      &--vertical {
        /* Box-model */
        display: none;
      }
    }
  }

  & .clio-cloud-platform {
    /* Box-model */
    width: 1248px;
    height: 881px;

    &__content {
      /* Box-model */
      display: flex;

      /* Flexible Box */
      justify-content: space-between;
    }
  }

  & .hardware-products {
    /* Box-model */
    width: 1248px;
    height: 629px;

    &__content {
      /* Box-model */
      display: flex;

      /* Flexible Box */
      justify-content: space-between;

      & .overview {
        &__title {
          /* Typography */
          text-align: left;
        }
      }
    }
  }

  & .download {
    /* Box-model */
    width: 424px;
    height: 170px;
  }
}

@media (max-width: 1365px) {
  .peer {
    & .peer-series {
      /* Box-model */
      width: 100vw;
      height: 276.3vw; /// 884/320 = 2.7625
      margin-top: 255.9vw; /// 819/320 = 2.559375

      /* Flexible Box */
      flex-direction: column;
      justify-content: unset;

      &__img {
        /* Box-model */
        width: 90vw; /// 288/320 = 0.9
        height: 43.8vw; /// 140/320 = 0.4375
        margin-top: 20.3vw; /// 65/320 = 0.203125

        & img {
          /* Box-model */
          width: 100%;
        }
      }

      &__content {
        /* Box-model */
        width: 90vw; /// 288/320 = 0.9
        height: 163.1vw; /// 522/320 = 1.63125
        margin-top: 17.8vw; /// 57/320 = 0.178125

        /* Typography */
        font-size: 5vw; /// 16/320 = 0.05
        line-height: 7.8vw; /// 25/320 = 0.078125
      }
    }

    & .key-features {
      /* Box-model */
      width: 100vw;
      height: 489.4vw; /// 1566/320 = 4.89375

      &__content {
        /* Box-model */
        margin-top: 7.5vw; /// 24/320 = 0.075

        /* Flexible Box */
        flex-direction: column;
        justify-content: unset;
        align-items: center;
      }
    }

    & .overview {
      /* Box-model */
      width: 90vw; /// 288/320 = 0.9

      &:not(:first-child) {
        /* Box-model */
        margin-top: 14.7vw; /// 47/320 = 0.146875
      }

      &__image {
        /* Box-model */
        width: 71.9vw; /// 230/320 = 0.71875
        height: 36.9vw; /// 118/320 = 0.36875
        margin: 0 auto;

        & img {
          /* Box-model */
          width: 100%;
        }
      }

      &__title {
        /* Box-model */
        margin-top: 7.5vw; /// 24/320 = 0.075

        /* Typography */
        font-size: 6.6vw; /// 21/320 = 0.065625
        line-height: 8.1vw; /// 26/320 = 0.08125
      }

      &__subTitle {
        /* Typography */
        font-size: 5vw; /// 16/320 = 0.05
        line-height: 8.1vw; /// 26/320 = 0.08125
      }

      &__description {
        /* Box-model */
        margin-top: 7.5vw; /// 24/320 = 0.075

        /* Typography */
        font-size: 5vw; /// 16/320 = 0.05
        line-height: 7.8vw; /// 25/320 = 0.078125
      }
    }

    & .infrastructure {
      /* Box-model */
      width: 100vw;
      height: 235.6vw; /// 754/320 = 2.35625

      &__title {
        /* Box-model */
        margin-top: 16.3vw; /// 52/320 = 0.1625
      }
  
      &__video {
        &--horizontal {
          /* Box-model */
          display: none;
        }
        &--vertical {
          /* Box-model */
          display: block;
          width: 90vw; /// 288/320 = 0.9
          margin: 0 auto;
        }
      }
    }

    & .clio-cloud-platform {
      /* Box-model */
      width: 100vw;
      height: 668.8vw; /// 2140/320 = 6.6875

      &__title {
        /* Box-model */
        margin-top: 15vw; /// 48/320 = 0.15
      }

      &__content {
        /* Box-model */
        margin-top: 7.5vw; /// 24/320 = 0.075

        /* Flexible Box */
        flex-direction: column;
        justify-content: unset;
        align-items: center;
      }
    }

    & .hardware-products {
      /* Box-model */
      width: 100vw;
      height: 493.1vw; /// 1578/320 = 4.93125

      &__content {
        /* Box-model */
        margin-top: 7.5vw; /// 24/320 = 0.075
        
        /* Flexible Box */
        flex-direction: column;
        justify-content: unset;
        align-items: center;
      }
    }

    & .download {
      /* Box-model */
      height: 63.3vw; /// 202.5/320 = 0.6328125
    }
  }
}
