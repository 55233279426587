@import "../../styles/_style";

footer.footer {
  /* Positioning */
  z-index: z-index(contents);

  /* Box-model */
  display: flex;
  width: 1366px;
  height: 400px;
  margin: 0 auto;

  & a {
    /* Typography */
    text-decoration: none;

    /* Visual */
    user-select: none;
  }

  & .disabled {
    /* Make the unusable parts invisible. */
    /* Visual */
    visibility: hidden;
  }

  & .container {
    /* Box-model */
    display: flex;
    width: 100%;
    padding-top: 85px;
    padding-right: 306px;
    padding-left: 112px;

    /* Flexible Box */
    justify-content: space-between;

    &__site-map {
      /* Box-model */
      display: flex;

      & .page {
        &__name {
          /* Box-model */
          margin-bottom: 14px;

          /* Typography */
          color: $white-100;
        }

        &__internal-link {
          /* Box-model */
          display: flex;

          /* Flexible Box */
          flex-direction: column;

          &>a {
            /* Box-model */
            margin-bottom: 5px;

            /* Typography */
            color: $gray-content;
            text-decoration: none;

            &.active {
              /* Typography */
              color: $red-text;
            }

            &:hover {
              /* Typography */
              text-decoration: underline;

              &.active {
                /* Typography */
                text-decoration: none;
                cursor: default;
              }
            }
          }
        }

        &~.page {
          margin-left: 33px;
        }
      }
    }
  }
}

@media (max-width: 1365px) {
  footer.footer {
    /* Box-model */
    width: 100%;
    height: 100.9vw; /// 323/320 = 1.009375

    /* Visual */
    background: $black-100;

    .container {
      all: unset;

      /* Box-model */
      width: 100vw;
      margin: 0 auto;

      &__site-map {
        /* Box-model */
        display: none;
      }
    }
  }
}
