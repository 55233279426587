@import "../../styles/style";

.bottom-background {
  /* Positioning */
  position: relative;
  top: 0px;

  /* Box-model */
  width: 100%;
  height: 1462px;

  &--home {
    /* Positioning */
    top: -97px;
  }

  &--peer {
    /* Positioning */
    top: -97px;
  }

  &.is-services {
    /* Positioning */
    top: -2px;
  }

  &__applications-section {
    /* Positioning */
    position: relative;
    z-index: z-index(background);

    /* Box-model */
    width: 100%;
    height: 863px;

    &--title {
      /* Positioning */
      position: absolute;
      top: -192px;

      /* Box-model */
      width: 100%;
      height: 100%;

      /* Visual */
      background: $gray-application-background;
      transform: skewY(-5deg);
      transform-origin: 50% 0%;
    }

    &--block {
      /* Positioning */
      position: absolute;
      top: 181px;

      /* Box-model */
      width: 100%;
      height: 682px;

      /* Visual */
      background: $white-100;
    }
  }

  &__cases-section {
    /* Box-model */
    width: 100%;
    height: 481px;

    &--title {
      /* Box-model */
      width: 100%;
      height: 181px;

      /* Visual */
      background: $gray-arisan-info;
    }

    &--content {
      /* Box-model */
      width: 100%;
      height: 300px;

      /* Visual */
      background: $white-100;
    }
  }

  &__contact-us-section {
    /* Box-model */
    width: 100%;
    height: 581px;

    /* Visual */
    background-color: $white-100;

    &--title {
      /* Box-model */
      height: 181px;

      /* Visual */
      background: $gray-arisan-info;
    }

    &--content {
      /* Box-model */
      height: 400px;
    }
  }

  &__footer {
    /* Box-model */
    height: 400px;

    /* Visual */
    background: $black-100;
  }
}


@media (max-width: 1365px) {
  .bottom-background {
    /* Positioning */
    top: 0px;

    /* Box-model */
    height: 200.6vw; /// 642/320 = 2.00625

    &--peer {
      /* Positioning */
      top: -20vw;
    }

    &.is-services {
      /* Positioning */
      top: 18.2vw; /// 60/320 = 0.1875 - 2/320
    }

    &__applications-section {
      /* Box-model */
      width: 100%;
      height: 133.8vw; /// 428/320 = 1.3375

      &::before {
        /* Positioning */
        position: absolute;

        /* Box-model */
        display: block;
        width: 100%;
        height: 14vw;

        /* Visual */
        background-color: $gray-application-background;
        transform: skewY(-8deg);
        transform-origin: top left;
        content: '';
      }

      &--title {
        all: unset;

        /* Positioning */
        position: absolute;
        top: 0;

        /* Box-model */
        width: 100%;
        height: 33.8vw; /// 108/320 = 0.3375

        /* Visual */
        background: $gray-application-background;
      }

      &--block {
        /* Positioning */
        top: 33.8vw; /// 108/320 = 0.3375

        /* Box-model */
        width: 100%;
        height: 100vw;
      }
    }

    &__cases-section {
      /* Box-model */
      height: 99.4vw; /// 318/320 = 0.99375

      &--title {
        /* Box-model */
        height: 33.4vw; /// 107/320 = 0.334375
      }

      &--content {
        /* Box-model */
        height: 100%;
      }
    }

    &__contact-us-section {
      /* Box-model */
      height: 101.3vw; /// 324/320 = 1.0125

      &--title {
        /* Box-model */
        height: 33.4vw; /// 107/320 = 0.334375
      }

      &--content {
        /* Box-model */
        height: 67.8vw; /// 217/320 = 0.678125
      }
    }

    &__footer {
      /* Box-model */
      height: 100.9vw; /// 323/320 = 1.009375
    }
  }
}
