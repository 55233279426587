@import "../../styles/style";

.about-us-background {
  /* Positioning */
  z-index: z-index(background);

  &__header {
    /* Box-model */
    width: 100vw;
    height: 291px;

    /* Visual */
    background: $black-background;
  }

  &__mission {
    /* Box-model */
    width: 100vw;
    height: 1058px;

    /* Visual */
    background: $black-background;
  }

  &__team {
    /* Positioning */
    position: relative;

    /* Box-model */
    width: 100vw;
    height: calc(3410px - 75px);

    /* Visual */
    background: $black-background;

    &--triangle {
      /* Positioning */
      position: absolute;
      top: -96px;

      /* Box-model */
      width: 100vw;
      height: 1705px;

      /* Visual */
      background: $gray-faceta-header-background;
      transform: skewY(-8deg);
      transform-origin: 50% 0%;
    }

    &--bottom {
      /* Positioning */
      position: absolute;
      bottom: 0px;

      /* Box-model */
      width: 100vw;
      height: 2200px;

      /* Visual */
      background: $gray-faceta-header-background;
    }
  }
}

@media (max-width: 1365px) {
  .about-us-background {
    &__header {
      /* Box-model */
      width: 100vw;
      height: 34.7vw; /// 175/320 = 0.546875 - 20vw = 34.7vw
    }

    &__mission {
      /* Box-model */
      width: 100vw;
      height: 240.3vw; /// 769/320 = 2.403125
    }

    &__team {
      /* Box-model */
      width: 100vw;
      height: 792.5vw; /// 2536/320 = 7.925

      &--triangle {
        /* Positioning */
        top: -7vw; /// 22.5/320 = 0.0703125

        /* Box-model */
        width: 100vw;
        height: 458.8vw; /// 1500/320 = 4.6875
      }

      &--bottom {
        /* Box-model */
        width: 100vw;
        height: 458.8vw; /// 1500/320 = 4.6875
      }
    }
  }
}
