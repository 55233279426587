@import "../../styles/_style";
$backgroundImageUrl: "../../assets/images/contact_form_logo.png";

.contact-form {
  /* Positioning */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: z-index(contact-form);

  /* Box-model */
  width: 100vw;
  height: 100vh;

  /* Typography */
  background: $black-contact-form-background;

  & input,
  textarea {
    /* Visual */
    background-color: $input-box-color;
    border: 1px solid $input-box-color;
    outline: none;
    box-sizing: border-box;

    /* Typography */
    font-family: arisan-font-normal;

    &:focus {
      /* Visual */
      background-color: $input-focus-color;
      border: 1px solid $gray-content;
      box-shadow: 0 3px 3px $input-focus-shadow-color;
    }

    &::placeholder {
      opacity: 0.4;
    }
  }

  &__form-container {
    /* Positioning */
    position: fixed;
    top: 50%;
    left: 50%;

    /* Box-model */
    display: flex;
    max-width: 100%;
    max-height: 100%;
    min-width: 850px;
    min-height: 650px;

    /* Flexible Box */
    flex-direction: column;

    /* Visual */
    background: $white-100;
    transform: translate(-50%, -50%);

    & .form {
      /* Positioning */
      position: relative;
      z-index: z-index(contents);

      /* Box-model */
      height: 440px;
      padding: 0 71px 0 69px;

      /* Typography */
      color: $black-text;

      &__close-button {
        /* Positioning */
        position: absolute;
        top: 20px;
        right: 20px;

        &:hover {
          /* Typography */
          cursor: pointer;
        }
      }

      &__header {
        /* Box-model */
        margin-top: 29px;
        margin-bottom: 6px;
      }

      &__checkbox {
        /* Box-model */
        display: flex;

        /* Flexible Box */
        align-items: center;

        &--item {
          /* Positioning */
          position: relative;

          /* Flexible Box */
          flex: 1;

          &:nth-child(1) {
            /* Box-model */
            max-width: 217px;
          }

          &:nth-child(2) {
            /* Box-model */
            max-width: 222px;
            margin-left: 26px;
          }

          &:nth-child(3) {
            /* Box-model */
            max-width: 213px;
            margin-left: 32px;
          }

          & > label {
            /* Box-model */
            display: flex;
            margin-left: -12px;

            & input[type="checkbox"] {
              /* Visual */
              opacity: 0;

              &:checked {
                & + .not-allowed {
                  /** Use customRequest.length === 1 and :check to decide
                    * whether cursor: not-allow.
                    */
                  /* Typography */
                  cursor: not-allowed;
                }

                & + .form__checkbox--text {
                  /* Typography */
                  color: $red-text;

                  &::after {
                    /* Positioning */
                    position: absolute;
                    top: 5px;
                    left: 2px;

                    /* Box-model */
                    width: 16px;
                    height: 6px;
                    border-bottom: 2px solid $red-text;
                    border-left: 2px solid $red-text;

                    /* Visual */
                    content: "";
                    transform: rotate(-45deg);
                  }
                }
              }
            }
          }
        }

        &--text::before {
          /* Positioning */
          position: relative;
          top: 2px;
          left: -10px;

          /* Box-model */
          display: inline-block;
          height: 16px;
          width: 16px;

          /* Typography */
          letter-spacing: 10px;

          /* Visual */
          border: 2px solid #cccccc;
          border-radius: 2px;
          content: "";
        }
      }

      &__underline {
        /* Box-model */
        margin-top: 21px;
        margin-bottom: 10px;

        /* Visual */
        border-top: 1px solid $gray-content;
      }

      &__content-box {
        /* Box-model */
        display: flex;
      }

      &__content {
        /* Flexible Box */
        flex: 1;

        &:nth-child(1) {
          /* Box-model */
          max-width: 217px;
        }

        &:nth-child(2) {
          /* Box-model */
          width: 222px;
          margin-left: 26px;
        }

        &:nth-child(3) {
          /* Box-model */
          max-width: 213px;
          margin-left: 32px;
        }

        & .custom-message {
          /* Box-model */
          display: flex;

          /* Flexible Box */
          flex-direction: column;

          &__title {
            /* Box-model */
            margin-bottom: 3px;
          }

          &__textarea {
            /* Box-model */
            width: 213px;
            max-width: 213px;
            height: 160px;
            padding: 15px 14px 0 15px;
            font-size: inherit;

            /* Typography */
            line-height: 21px;
          }
        }
      }

      &__submit {
        /* Box-model */
        width: 130px;
        height: 37px;
        margin-top: 12px;

        /* Typography */
        color: $white-100;
        cursor: pointer;

        /* Visual */
        background-color: $red-text;
        border-radius: 4px;

        &.disable {
          /* Typography */
          cursor: not-allowed;

          /* Visual */
          background-color: $gray-content;
        }
      }
    }
  }

  &__contact-info {
    /* Positioning */
    z-index: z-index(contents);

    /* Box-model */
    display: flex;
    margin-top: 57px;
    margin-right: 135px;

    /* Flexible Box */
    align-self: flex-end;
  }

  &__contact-info-background {
    /* Positioning */
    position: absolute;
    bottom: 0;

    /* Box-model */
    width: 850px;
    height: 221px;
  }
}

@media (max-width: 1365px) {
  .contact-form {
    /* Box-model */
    overflow-x: hidden;
    overflow-y: scroll;

    /* Visual */
    background: unset;
    box-sizing: border-box;

    * {
      /* Visual */
      box-sizing: border-box;
    }

    &__form-container {
      position: relative;
      top: 0;
      left: 0;

      /* Box-model */
      width: 100%;
      max-width: unset;
      max-height: unset;
      min-width: 320px;
      min-height: unset;

      /* Flexible Box */
      align-items: center;

      /* Visual */
      background: unset;
      transform: unset;

      & .form {
        /* Positioning */
        position: unset;
        z-index: z-index(contents);

        /* Box-model */
        display: flex;
        width: 100%;
        height: unset;
        padding: 0 5vw; /// 16/320 = 0.05
        margin-bottom: 10vw;

        /* Flexible Box */
        flex-direction: column;

        /* Visual */
        background: $white-100;

        &__close-button {
          /* Positioning */
          position: unset;

          /* Box-model */
          display: flex;
          width: 9.1vw; /// 29/320 = 0.090625
          height: 9.1vw; /// 29/320 = 0.090625
          margin-top: 5vw;

          /* Flexible Box */
          justify-content: center;
          align-items: center;
          align-self: flex-end;

          /* Visual */
          background: $gray-arisan-info;

          img {
            /* Box-model */
            width: 70%;
            height: 70%;
          }
        }

        &__header {
          /* Box-model */
          margin: 0px;
        }

        &__checkbox {
          /* Box-model */
          height: 29.7vw; /// 95/320 = 0.296875

          /* Flexible Box */
          flex-direction: column;
          align-items: flex-start;

          &--item {
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
              /* Box-model */
              max-width: unset;
              margin: 0;
            }

            & > label {
              /* Box-model */
              margin-left: 0;

              & input[type="checkbox"] {
                /* Box-model */
                display: none;

                &:checked {
                  & + .form__checkbox--text {
                    &::after {
                      /* Positioning */
                      top: 1.6vw; /// 5/320 = 0.015625
                      left: 0.6vw; /// 2/320 = 0.00625

                      /* Box-model */
                      width: 5vw; /// 16/320 = 0.05
                      height: 1.9vw; /// 6/320 = 0.01875
                      border-bottom: 0.6vw solid $red-text; /// 2/320 = 0.00625
                      border-left: 0.6vw solid $red-text;
                    }
                  }
                }
              }
            }
          }

          &--text {
            /* Positioning */
            position: relative;

            /* Box-model */
            display: flex;
            align-items: center;

            &::before {
              /* Positioning */
              left: 0;
              top: 0;

              /* Box-model */
              height: 5vw;
              width: 5vw;
              margin-right: 3.4vw; /// 11/320 = 0.034375

              /* Typography */
              letter-spacing: unset;

              /* Visual */
              border: 0.6vw solid #cccccc;
              border-radius: 0.6vw;
              content: "";
            }
          }
        }

        &__underline {
          /* Box-model */
          margin-top: 3.1vw; /// 10/320 = 0.03125
          margin-bottom: 5vw; /// 16/320 = 0.05

          /* Visual */
          border-top: 0.3vw solid $gray-content; /// 1/320 = 0.003125
        }

        &__content-box {
          /* Flexible Box */
          flex-direction: column;
        }

        &__content {
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3) {
            /* Box-model */
            width: 100%;
            max-width: unset;
            margin: 0px;
          }

          .custom-message {
            /* Box-model */
            display: flex;

            /* Flexible box */
            flex-direction: column;

            &__title {
              /* Box-model */
              margin-bottom: 0.9vw; /// 3/320 = 0.009375

              /* Typography */
              font-size: 4.4vw; /// 14/320 = 0.04375
            }

            &__textarea {
              /* Box-model */
              width: 100%;
              height: 50vw; /// 160/320 = 0.5
              max-width: unset;
              padding: 4.7vw 6.6vw; /// 15/320 = 0.046875, 21/320 = 0.065625

              /* Visual */
              border-radius: 1.3vw; /// 4/320 = 0.0125

              /* Typography */
              font-size: 4.4vw; /// 14/320 = 0.04375
              line-height: 6.6vw; /// 21/320 = 0.065625
            }
          }
        }

        &__submit {
          /* Flexible box */
          align-self: center;

          /* Box-model */
          width: 40.6vw; /// 130/320 = 0.40625
          height: 11.6vw; /// 37/320 = 0.115625
          margin-top: 3.8vw; /// 12/320 = 0.0375
        }
      }
    }

    &__contact-info {
      all: unset;
      /* Positioning */
      position: relative;
      z-index: z-index(contents);

      /* Box-model */
      width: 100%;
      height: 90.6vw; /// 290/320 = 0.90625
    }

    &__contact-info-background {
      /* Box-model */
      width: 462.8vw; /// 1481/320 = 4.628125
      height: 120.3vw; /// 385/320 = 1.203125

      /* Visual */
      background: white;
    }
  }
}
