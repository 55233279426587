@import "../../styles/_style";

.services-clio {
  /* Positioning */
  z-index: z-index(contents);

  /* Box-model */
  display: flex;
  width: 1366px;
  margin: 388px auto 0;

  /* Flexible Box */
  flex-direction: column;

  &__title {
    /* Box-model */
    display: flex;
    margin-bottom: 85px;

    /* Flexible Box */
    flex-direction: column;
    align-items: center;

    &--text {
      /* Box modal */
      margin-bottom: 12px;

      /* Typography */
      color: $black-text;
    }

    &--underline {
      /* Box-model */
      width: 80px;

      /* Visual */
      border-bottom: 2px solid $red-text;
    }
  }

  &__content {
    /* Box-model */
    display: flex;
    height: 430px;
    margin: 0 112px 85px;
  }

  & .logo {
    /* Box-model */
    padding: 0 56px 0 112px;

    /* Flexible Box */
    flex: 1;

    &__container {
      /* Box-model */
      display: flex;
      margin-top: 51px;
      margin-left: 62px;
    }
  }

  &__introduction {
    /* Box-model */
    padding: 0 112px 0 56px;

    /* Flexible Box */
    flex: 1;

    & .introduction {
      &__title {
        /* Box-model */
        margin-bottom: 30px;

        /* Typography */
        line-height: 1.17;
        color: $red-text;
      }

      &__content {
        /* Box-model */
        margin-bottom: 30px;

        /* Typography */
        line-height: 1.62;
        color: $black-text;
      }

      &__icons {
        /* Box-model */
        display: flex;
        padding-top: 10px;
        margin-bottom: 30px;
      }

      &__icon {
        /* Positioning */
        position: relative;

        /* Box-model */
        margin-right: 23.7px;

        & > img:nth-child(2) {
          /* Positioning */
          position: absolute;
          top: 0;
          left: 0;
        }

        &:hover {
          /* Positioning */
          position: relative;
          top: -10px;
        }
      }
    }
  }

  &__bottom {
    /* Box-model */
    margin-top: 85px;
  }
}

.services-product {
  /* Box-model */
  width: 1366px;
  margin: 0 auto;

  &__content {
    /* Box-model */
    display: flex;
    width: 910px;
    margin: 0 auto;

    /* Flexible */
    justify-content: space-between;
  }

  &__introduction {
    /* Box-model */
    width: 404px;

    &__title {
      /* Box-model */
      width: 100%;

      /* Typography */
      font-family: arisan-font-semi-bold;
      font-size: 48px;
      line-height: 52px;

      &.isFaceta {
        /* Typography */
        color: $blue-faceta;
      }

      &.isPeer {
        /* Typography */
        color: $purple-peer;
      }
    }

    &__content {
      /* Box-model */
      width: 100%;
      margin-top: 24px;

      /* Typography */
      font-size: 16px;
      line-height: 25px;
      color: $gray-content;
    }

    & .icons {
      /* Box-model */
      display: flex;
      width: 100%;
      height: 80px;
      margin-top: 38px;

      /* Flexible Box */
      justify-content: space-between;
      
      &__link {
        &:hover {
          /* Positioning */
          position: relative;
          top: -10px;
        }
      }
    }
  }
}

.services-custom-solutions {
  /* Positioning */
  z-index: z-index(contents);

  /* Box-model */
  display: flex;
  padding-bottom: 98px;
  margin: 164px auto 306px auto;
  width: 1366px;

  /* Flexible Box */
  flex-direction: column;

  /* Typography */
  color: $white-100;

  &__top {
    /* Visual */
    background: $black-100;
  }

  &__title {
    /* Box-model */
    display: flex;
    margin-bottom: 85px;

    /* Flexible Box */
    flex-direction: column;
    align-items: center;

    &--text {
      /* Box-model */
      margin-bottom: 12px;
    }

    &--underline {
      /* Box-model */
      width: 80px;

      /* Visual */
      border-bottom: 2px solid $red-text;
    }
  }

  &__content {
    /* Box-model */
    display: flex;
    margin: 0 112px 85px;
  }

  &__introduction {
    /* Box-model */
    padding: 0 56px 0 112px;

    /* Flexible Box */
    flex: 1;

    & .introduction {
      &__title {
        /* Box-model */
        margin-bottom: 30px;

        /* Typography */
        line-height: 1.17;
        color: $red-text;
      }

      &__content {
        /* Box-model */
        padding-bottom: 29px;
        margin-bottom: 40px;

        /* Typography */
        line-height: 1.62;
        letter-spacing: 0.4px;
        color: $gray-content;
      }

      &__more-detail {
        /* Box-model */
        display: flex;
        width: 191px;
        height: 48px;

        /* Flexible Box */
        align-items: center;

        /* Visual */
        border: 1px solid #707070;
        border-radius: 5px;
        user-select: none;

        &--text {
          /* Box-model */
          margin-left: 27px;

          /* Typography */
          font-size: 18px;
          color: #a3a3a3;
          letter-spacing: 0.46px;
        }

        &--arrow {
          /* Box-model */
          margin-top: 3px;
          margin-left: 22px;
        }

        &:hover {
          /* Typography */
          cursor: pointer;

          /* Visual */
          background: $red-text;
          border: 1px solid $red-text;

          & .introduction__more-detail {
            &--text {
              /* Typography */
              color: $white-100;
            }

            &--arrow {
              /* Visual */
              filter: brightness(100);
            }
          }
        }
      }
    }
  }

  &__customize {
    /* Box-model */
    display: flex;
    padding: 0 112px 0 56px;

    /* Flexible Box */
    flex: 1;
    flex-direction: column;
    align-items: center;

    & .customize {
      &__icon {
        /* Box-model */
        display: flex;
        margin: 50px 0 40px;

        &--image {
          /* Box-model */
          margin: 0 auto;
        }
      }

      &__solution {
        /* Positioning */
        position: relative;

        /* Box-model */
        display: flex;
        margin-bottom: 11px;

        /* Flexible Box */
        align-items: center;

        &--checkbox {
          /* Positioning */
          position: relative;
          top: 2px;
        }

        &--red-sign {
          /* Positioning */
          position: absolute;
          top: 0px;
        }

        &--text {
          margin-left: 18px;
        }
      }
    }
  }
}


.more-detail {
  /* Box-model */
  display: block;
  width: 191px;
  height: 50px;
  margin-top: 40px;

  /* Visual */
  border: 1px solid $gray-contact-us-button;
  border-radius: 5px;

  &__container {
    /* Box-model */
    display: flex;
    margin-left: 28px;

    /* Typography */
    line-height: 50px;

    &__text {
      /* Box-model */
      margin-right: 24.5px;

      /* Typography */
      font-size: 18px;
      color: $gray-contact-us-button;
    }
  }

  &:hover {
    /* Visual */
    background: $red-text;
    border: 1px solid $red-text;

    & .more-detail__container {
      &__text {
        /* Typography */
        color: $white-100;
      }

      &__arrow {
        /* Visual */
        filter: brightness(100);
      }
    }
  }
}

.mobile-break {
  /* Box-model */
  display: none;
}

.desktop-break {
  /* Box-model */
  display: inline;
}

@media (max-width: 1365px) {
  .services-clio {
    /* Box-model */
    width: 100vw;
    margin: 60vw auto 0;

    &__title {
      /* Box-model */
      margin-bottom: 0;

      &--text {
        /* Box modal */
        margin-bottom: 3.8vw; /// 12/320=0.0375
        /* Typography */
        font-size: 6.9vw; /// 22/320=0.06875
      }

      &--underline {
        /* Box-model */
        width: 12.5vw; /// 40/320=0.125
      }
    }
  
    &__content {
      /* Box-model */
      height: 171.4vw; /// 548.5 / 320
      margin: 0;

      /* Flexible Box */
      flex-direction: column;
      align-items: center;
    }
  
    & .logo {
      /* Box-model */
      margin-top: 15vw;
      padding: 0;
  
      &__container {
        /* Box-model */
        display: block;
        width: 57.5vw; /// 184/320
        margin-top: 0;
        margin-left: 0;

        & img {
          /* Box-model */
          width: 100%;
        }
      }
    }
  
    &__introduction {
      /* Box-model */
      margin-top: 21.3vw; /// 68/320=0.2125
      padding: 0;
      width: 90vw;
  
      & .introduction {
        &__title {
          /* Box-model */
          margin-bottom: 2.5vw;

          /* Typography */
          line-height: 12.5vw; /// 40/320=0.125
        }
  
        &__content {
          /* Box-model */
          margin-bottom: 6.9vw; /// 22/320=0.06875
  
          /* Typography */
          line-height: 7.8vw; /// 25/320=0.078125
          font-size: 5vw; /// 16/320=0.05
          letter-spacing: 0.1px;
        }

        &__icons {
          /* Box-model */
          padding-top: 3.1vw; /// 10/320=0.03125
          margin-bottom: 9.4vw; /// 30/320=0.09375
        }
  
        &__icon {
          /* Box-model */
          width: 17.8vw;
          margin-right: 6.3vw; /// 20/320=0.0625

          & img {
            /* Box-model */
            width: 100%;
          }
        }
      }
    }

    &__bottom {
      /* Positioning */
      position: relative;
      left: -50%;

      /* Box-model */
      width: 213vw;
      margin-top: 6.3vw; /// 20/320=0.0625

      & img {
        /* Box-model */
        width: 100%;
      }
    }
  }

  .services-product {
    /* Box-model */
    width: 100vw;
    margin-bottom: 28.9vw; /// 92.5/320 = 0.2890625

    &__content {
      /* Box-model */
      width: 100vw;

      /* Flexible Box */
      flex-direction: column-reverse;
      justify-content: unset;
      align-items: center;
    }

    &__logo {
      /* Box-model */
      width: 62.5vw; /// 200/320 = 0.625
      margin: 15vw auto; /// 48/320 = 0.15

      & img {
        /* Box-model */
        width: 100%;
      }
    }

    &__introduction {
      /* Box-model */
      width: 90vw; /// 288/320 = 0.9

      &__title {
        /* Typography */
        font-family: arisan-font-semi-bold;
        font-size: 10.3vw; /// 33/320 = 0.103125
        line-height: 12.5vw; /// 40/320 = 0.125
      }

      &__content {
        /* Box-model */
        margin-top: 2.5vw; /// 8/320 = 0.025

        /* Typography */
        font-size: 5vw; /// 16/320 = 0.05
        line-height: 7.8vw; /// 25/320 = 0.078125
      }

      & .icons {
        /* Box-model */
        height: 17.8vw; /// 57/320 = 0.178125
        margin-top: 7.5vw; /// 24/320 = 0.075

        & a {
          /* Box-model */
          display: block;
          width: 17.8vw; /// 57/320 = 0.178125 

          & img {
            /* Box-model */
            width: 100%;
          }
        }
      }
    }
  }

  .services-custom-solutions {  
    /* Box-model */
    width: 100vw;
    padding-bottom: 0;
    margin: 8.8vw 0 37.5vw; /// 28/320=0.0875 120/320=0.375

    &__title {
      /* Box-model */
      margin-bottom: 0;

      &--text {
        /* Box-model */
        margin-bottom: 3.75vw; /// 12/320=0.0375
        /* Typography */
        font-size: 6.875vw; /// 22/320=0.06875
      }

      &--underline {
        /* Box-model */
        width: 12.5vw; /// 40/320=0.125
      }
    }

    &__top {
      /* Positioning */
      position: relative;

      /* Box-model */
      width: 213.4vw; /// 683/320=2.134375
      margin-bottom: 5.1vw; /// 16.3/320=0.0509375

      & img {
        /* Box-model */
        width: 100%;
      }
    }

    &__title {
      /* Box-model */
      margin-bottom: 18.1vw; /// 58/320=0.18125
    }
  
    &__content {
      /* Box-model */
      margin: 0;

      /* Flexible Box */
      flex-direction: column-reverse;
    }
  
    &__introduction {
      /* Box-model */
      width: 90vw;
      padding: 0;
      margin: 0 auto;
  
      & .introduction {
        &__title {
          /* Box-model */
          margin-bottom: 2.5vw; /// 8/320=0.025

          /* Typography */
          line-height: 12.5vw; /// 40/320=0.125
        }
  
        &__content {
          /* Box-model */
          margin-bottom: 12.5vw; /// 40/320=0.125
          padding-bottom: 0;

          /* Typography */
          line-height: 7.8vw; /// 25/320=0.078125
          font-size: 5vw; /// 16/320=0.05
        }
  
        &__more-detail {
          /* Box-model */
          width: 90vw; /// 288/320
          height: 15vw; /// 48/320
  
          /* Flexible Box */
          justify-content: center;
  
          &--text {
            /* Box-model */
            margin-right: 5.3vw;

            /* Typography */
            font-size: 5.6vw;
          }

          &--arrow {
            /* Box-model */
            width: 3vw;
          }
        }
      }
    }

    &__customize {
      /* Box-model */
      margin-bottom: 5.6vw; /// 18/320=0.05625
      padding: 0;
  
      /* Flexible Box */
      flex: 1;
      flex-direction: column;
      align-items: center;
  
      & .customize {
        /* Box-model */
        display: flex;
          
        /* Flexible Box */
        flex-direction: column;
        align-items: center;

        &__icon {
          /* Box-model */
          width: 24.8vw; /// 79.3/320=0.2478125
          margin: 0px 0 12.5vw; /// 40/320=0.125
  
          &--image {
            /* Box-model */
            width: 100%;
          }
        }
  
        &__solution {
          /* Box-model */
          margin-bottom: 3.4vw; /// 11/320=0.034375

          &--checkbox {
            /* Positioning */
            position: relative;
            top: 2px;

            /* Box-model */
            width: 3.7vw; /// 11.75/320=0.03671875

            & img {
              /* Box-model */
              width: 100%;
            }
          }
  
          &--red-sign {
            /* Positioning */
            position: absolute;
            top: 50%;

            /* Visual */
            transform: translateY(-50%);

            /* Box-model */
            width: 5vw; /// 16/320=0.05

            & img {
              /* Box-model */
              width: 100%;
            }
          }

          &--text {
            /* Typography */
            font-size: 4.4vw; /// 14/320=0.04375
            line-height: 6.25vw; /// 20/320=0.0625
          }
        }
      }
    }
  }

  .more-detail {
    /* Box-model */
    width: 90vw;
    height: 13.8vw; /// 44/320 = 0.1375
    margin-top: 11.3vw; /// 36/320 = 0.1125

    &__container {
      /* Box-model */
      margin-left: unset;

      /* Flexible Box */
      justify-content: center;

      /* Typography */
      line-height: 13.8vw; /// 44/320 = 0.1375

      &__text {
        /* Box-model */
        margin-right: 5.3vw; /// 17/320 = 0.053125

        /* Typography */
        font-size: 5.6vw; /// 18/320 = 0.05625
      }

      &__arrow {
        /* Box-model */
        width: 3vw;
      }
    }
  }

  .element-position {
    /* Positioning */
    position: relative;
    top: -20vw;
  }

  .mobile-break {
    /* Box-model */
    display: inline;
  }
  
  .desktop-break {
    /* Box-model */
    display: none;
  }
}
