@import "../../styles/style";
$line-height-description: 1.5;

.home {
  * {
    /* Visual */
    box-sizing: border-box;
  }

  .video-section {
    /* Positioning */
    z-index: z-index(contents); /// 5

    /* Box-model */
    display: flex;
    width: 100%;
    height: 992px;

    /* Flexible Box */
    justify-content: center;

    &__information-container {
      /* Box-model */
      display: flex;
      width: 1015px;
      margin-top: 218px;

      /* Flexible Box */
      flex-direction: column;
      align-items: center;

      /* Typography */
      color: $white-100;
    }

    &__red-dot {
      /* Box-model */
      width: 32px;
      height: 43px;

      /* Flexible Box */
      align-self: flex-end;

      img {
        width: 100%;
        height: 100%;
      }

      &--blink {
        /* Visual */
        animation: red-dot 1s infinite;
      }

      @keyframes red-dot {
        70% {
          opacity: 1;
        }

        99% {
          opacity: 0;
        }

        100% {
          opacity: 1;
        }
      }
    }

    &__subtitle {
      /* Box-model */
      margin-bottom: 75px;

      /* Flexible Box */
      align-self: stretch;
    }

    &__subtitle-seconds {
      /* Box-model */
      display: flex;

      /* Flexible Box */
      align-items: center;

      &--line {
        /* Box-model */
        height: 1px;
        flex: 1;

        /* Visual */
        background: $white-100;
      }
    }

    &__subtitle-content {
      /* Box-model */
      padding: 6px 0 15px 0;

      /* Typography */
      text-align: center;
    }

    &__white-line-below {
      /* Visual */
      border-bottom: solid 1px $white-100;
    }

    &__scroll-down {
      /* Box-model */
      display: flex;
      width: 86px;
      height: 128px;

      /* Flexible Box */
      flex-direction: column;
      align-items: center;

      &--text {
        /* Box-model */
        margin-bottom: 20px;

        /* Typography */
        line-height: 25px;
      }

      &--icon {
        /* Box-model */
        width: 30px;
        height: 83px;

        img {
          /* Box-model */
          width: 100%;
          height: 100%;
        }
      }
    }

    &__more {
      /* Box-model */
      display: none;
    }
  }

  .reimagined-section {
    /* Positioning */
    z-index: z-index(contents); /// 5

    /* Box-model */
    display: flex;
    width: 100%;
    height: 470px;

    /* Flexible Box */
    justify-content: center;
    align-items: flex-start;

    /* Typography */
    color: $white-100;

    &__content {
      /* Box-model */
      display: flex;
      width: 100%;

      /* Flexible Box */
      justify-content: center;
      align-items: center;
    }

    &__red-polygon {
      /* Box-model */
      width: 46px;
      height: 55px;

      /* Typography */
      clip-path: polygon(60% 0, 100% 0, 40% 100%, 0 100%);
      -webkit-clip-path: polygon(60% 0, 100% 0, 40% 100%, 0 100%);

      /* Visual */
      background: $red-background;
    }

    &__video-security-text {
      /* Box-model */
      margin: 0 13px;
    }
  }

  .problems-section {
    /* Positioning */
    z-index: z-index(contents); /// 5

    /* Box-model */
    display: flex;
    width: 100%;
    height: 2207px;

    /* Flexible Box */
    flex-direction: column;
    align-items: center;

    .arisan-title {
      /* Box-model */
      margin-bottom: 113px;
    }
  }

  .slogan-section {
    /* Positioning */
    z-index: z-index(contents); /// 5

    /* Box-model */
    display: flex;
    width: 100%;
    height: 883px;

    /* Flexible Box */
    flex-direction: column;
    align-items: center;

    &__title-container {
      /* Box-model */
      display: flex;
      margin: 0 173px;
    }

    &__quotation-image {
      /* Positioning */
      position: relative;

      /* Box-model */
      width: 87px;

      img {
        /* Box-model */
        width: 100%;
        height: 100%;
      }

      &--top {
        /* Positioning */
        top: -100px;
      }

      &--bottom {
        /* Positioning */
        top: 75px;
      }
    }

    &__slogan {
      /* Box-model */
      margin: 0 15px;

      /* Typography */
      line-height: 68px;
      color: $white-home-slogan;
      text-align: center;

      &--red {
        /* Typography */
        color: $red-text;
      }

      .mobile-break {
        /* Box-model */
        display: none;
      }
    }

    &__description {
      /* Box-model */
      margin: 0 362px;

      /* Typography */
      font-size: 21px;
      line-height: 40px;
      text-align: center;
      color: $gray-about-us-paragraph;

      .mobile-break {
        /* Box-model */
        display: none;
      }
    }
  }

  .services-section {
    /* Positioning */
    z-index: z-index(contents); /// 5

    /* Box-model */
    width: 100%;
    height: 2570px;
  }

  .serverless-section {
    /* Positioning */
    z-index: z-index(contents); /// 5

    /* Box-model */
    display: flex;
    width: 100%;
    height: 1126px;

    /* Flexible Box */
    flex-direction: column;
    align-items: center;

    &__content-container {
      /* Box-model */
      display: flex;
      width: 100%;
      margin: 106px 0;
      padding: 0 247px;

      /* Flexible Box */
      justify-content: space-between;
    }

    &__introduction-container {
      /* Box-model */
      width: 451px;
    }

    &__title {
      /* Box-model */
      margin-bottom: 6px;

      /* Typography */
      color: $white-100;
    }

    &__description {
      /* Typography */
      color: $gray-content;
      line-height: 25px;

      .mobile-break {
        /* Box-model */
        display: none;
      }
    }

    &__images-container {
      /* Box-model */
      display: flex;
      width: 216px;
      height: 217px;

      /* Flexible Box */
      flex-direction: column;
      align-items: center;
    }

    &__server-image {
      /* Box-model */
      display: flex;
      width: 213px;
      height: 140px;

      /* Flexible Box */
      justify-content: center;
      align-items: center;

      img {
        /* Box-model */
        width: 100%;
        height: 100%;
      }
    }

    &__cross-image {
      /* Positioning */
      position: relative;

      /* Box-model */
      display: flex;
      width: 100%;

      /* Flexible Box */
      flex-grow: 1;
      justify-content: center;
      align-items: center;

      img {
        /* Positioning */
        position: absolute;

        /* Box-model */
        width: 100%;
        height: 100%;
      }
    }

    &__cross-text {
      /* Typography */
      color: $white-100;
      text-align: center;
    }

    &__icon-container {
      /* Box-model */
      display: flex;
      width: 100%;
      padding: 0 217px;

      /* Flexible */
      justify-content: space-between;
    }
  }
}

@media (max-width: 1365px) {
  .home {
    .video-section {
      /* Box-model */
      height: 191.6vw; /// 613/320 = 1.915625

      &__information-container {
        /* Box-model */
        width: 88.1vw; /// 282/320 = 0.88125
        margin-top: 52.2vw; /// 167/320 = 0.521875
      }

      &__red-dot {
        /* Box-model */
        width: 5.3vw; /// 17/320 = 0.053125
        height: 6.9vw; /// 22/320 = 0.06875
      }

      &__main-title {
        /* Typography */
        font-size: 10vw; /// 32/320 = 0.1
        letter-spacing: 0;
        line-height: 18.4vw; /// 59/320 = 0.184375
      }

      &__subtitle {
        /* Box-model */
        margin-bottom: 45.3vw; /// 145/320 = 0.453125
      }

      &__scroll-down {
        /* Box-model */
        display: none; /// hide the desktop version's icon
      }

      &__more {
        /* Box-model */
        display: flex;
        width: 10.6vw; /// 34/320 = 0.10625
        height: 14.1vw; /// 45/320 = 0.140625

        /* Flexible Box */
        flex-direction: column;
        align-items: center;

        &--text {
          /* Box-model */
          margin-bottom: 3.4vw; /// 11/320 = 0.034375

          /* Typography */
          font-size: 4.7vw; /// 15/320 = 0.046875
        }

        &--icon {
          /* Box-model */
          width: 8.4vw; /// 27/320 = 0.084375
          height: 4.4vw; /// 14/320 = 0.04375

          img {
            /* Box-model */
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .reimagined-section {
      /* Box-model */
      height: 68.8vw; /// 220/320 = 0.6875

      &__content {
        /* Box-model */
        padding-top: 10.9vw; /// 35/320 = 0.109375
      }

      &__red-polygon {
        /* Box-model */
        display: none;
      }

      &__video-security-text {
        /* Box-model */
        width: 65vw; /// 208/320 = 0.65

        /* Typography */
        text-align: center;
      }
    }

    .problems-section {
      /* Box-model */
      height: 550vw; /// 1760/320 = 5.5
      padding-top: 23px;

      .arisan-title {
        /* Box-model */
        margin-bottom: 3.1vw; /// 10/320 = 0.03125
      }
    }

    .slogan-section {
      /* Box-model */
      height: 209.1vw; /// 669/320 = 2.090625
      padding-top: 7.2vw; /// 23/320 = 0.071875

      &__title-container {
        /* Box-model */
        margin: 0 5.3vw; /// 17/320 = 0.053125

        /* Flexible Box */
        flex-direction: column;
        align-items: center;
      }

      &__quotation-image {
        /* Positioning */
        position: unset;

        /* Box-model */
        width: 6.3vw; /// 20/320 = 0.0625
        height: 11.3vw; /// 36/320 = 0.1125

        &--top {
          /* Flexible Box */
          align-self: flex-start;
        }

        &--bottom {
          /* Flexible Box */
          align-self: flex-end;
        }
      }

      &__slogan {
        /* Box-model */
        margin: unset;

        /* Typography */
        line-height: 12.5vw; /// 40/320 = 0.125
        color: $white-home-slogan;

        .mobile-break {
          /* Box-model */
          display: block;
        }

        .desktop-break {
          /* Box-model */
          display: none;
        }
      }

      &__description {
        /* Box-model */
        margin: 0 3.8vw; /// 12/320 = 0.0375

        /* Typography */
        font-size: 5.6vw; /// 18/320 = 0.05625
        line-height: 7.8vw; /// 25/320 = 0.078125

        .mobile-break {
          /* Box-model */
          display: block;
        }

        .desktop-break {
          /* Box-model */
          display: none;
        }
      }
    }

    .services-section {
      /* Box-model */
      height: 652.2vw; /// 2087/320 = 6.521875
      padding-top: 14.7vw; /// 47/320 = 0.146875
    }

    .serverless-section {
      /* Box-model */
      height: 452.2vw; /// 1447/320 = 4.521875
      padding-top: 14.7vw; /// 47/320 = 0.146875

      &__content-container {
        /* Box-model */
        width: 93%;
        margin: 7.5vw auto 0 auto; /// 24/320 = 0.075
        padding: unset;

        /* Flexible Box */
        flex-direction: column-reverse;
        justify-content: flex-start;
      }

      &__introduction-container {
        /* Box-model */
        width: 100%;
      }

      &__title {
        /* Box-model */
        margin-bottom: 2.5vw; /// 8/320 = 0.025
      }

      &__description {
        /* Typography */
        font-size: 5vw; /// 16/320 = 0.05
        line-height: 7.8vw; /// 25/320 = 0.078125

        .mobile-break {
          /* Box-model */
          display: block;
        }

        .desktop-break {
          /* Box-model */
          display: none;
        }
      }

      &__images-container {
        /* Box-model */
        width: 50.3vw; /// 161/320 = 0.503125
        height: 49.1vw; /// 157/320 = 0.490625
        margin: 0 auto 5.6vw auto; /// 18/320 = 0.05625
      }

      &__server-image {
        /* Box-model */
        width: 49.4vw; /// 158/320 = 0.49375
        height: 32.2vw; /// 103/320 = 0.321875
      }

      &__icon-container {
        /* Box-model */
        height: 195vw; /// 624/320 = 1.95
        margin-top: 11.3vw; /// 36/320 = 0.1125
        padding: unset;

        /* Flexible Box */
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}
