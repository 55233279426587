.header {
    /* Positioning */
    position: absolute;
    top: 0;

    /* Box-model */
    display: flex;
    width: 100vw;
    margin: auto;
    margin-top: 97px;

    /* Flexible Box */
    justify-content: center;
}
