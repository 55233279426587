@import "../../styles/style";
$line-height-description: 1.5;

.about-us {
  & .mission {
    /* Box-model */
    width: 1366px;
    height: 1058px;
    margin-top: 388px;

    &__content {
      /* Box-model */
      width: 694px;
      height: 230px;
      margin: 87px auto 53px;

      /* Typography */
      font-size: 21px;
      line-height: 40px;
      letter-spacing: 0.2px;
      color: $gray-about-us-paragraph;
      text-align: center;  
    }
  }

  & .team {
    /* Box-model */
    width: 1366px;
    height: 3410px;

    &__members {
      /* Box-model */
      display: grid;
      width: 960px;
      margin: 0 auto;

      /* Grid Box */
      grid-template-columns: 1fr 1fr;
      column-gap: 40px;
      row-gap: 53px;
      justify-items: center;
      align-items: center;

      &__quote {
        /* Typography */
        line-height: 40px;
        color: $black-text;
      }
    }

    .team__member-card {
      /* Positioning */
      position: relative;

      /* Box-model */
      width: 460px;
      height: 705px;

      /* Visual */
      background: $white-100;

      &__container {
        /* Positioning */
        position: absolute;
        top: 38px;
        left: 50%;

        /* Box-model */
        width: 383px;
        height: 625px;

        /* Visual */
        transform: translateX(-50%);
      }
      
      &__avatar {
        /* Box-model */
        width: 383px;
        height: 500px;

        & img {
          /* Box-model */
          width: 100%;
          height: 100%;
        }
      }

      &__name {
        /* Box-model */
        margin-top: 14px;

        /* Typography */
        line-height: 40px;
        color: $black-text;
      }

      &__job-title {
        /* Box-model */
        margin-top: 46px;

        /* Typography */
        line-height: 25px;
        color: $gray-about-us-paragraph;
        white-space: pre-line;
      }

      &__empty {
        /* Positioning */
        position: absolute;
        top: 50%;
        left: 50%;

        /* Box-model */
        width: 383px;
        height: 625px;
  
        /* Visual */
        background: $gray-content;
        transform: translate(-50%, -50%);

        /* Typography */
        font-size: 48px;
        line-height: 654px;
        color: $white-100;
        text-align: center;

        & a {
          all: unset;

          /* Typography */
          cursor: pointer;
        }
      }
    }
  }
}

@media (max-width: 1365px) {
  .about-us {
    & .mission {
      /* Box-model */
      width: 100vw;
      height: 240.3vw; /// 769/320 = 2.403125
      margin-top: 60.6vw; /// 194/320 = 0.60625

      &__content {
        /* Box-model */
        width: 90vw; /// 288/320 = 0.9
        height: 69.4vw; /// 222/320 = 0.69375
        margin: 7.5vw auto 13.8vw; /// 24/320 = 0.075, 44/320 = 0.1375

        /* Typography */
        font-size: 5vw; /// 16/320 = 0.05
        line-height: 7.8vw; /// 25/320 = 0.078125
        letter-spacing: 1px;
      }
    }

    & .team {
      /* Box-model */
      width: 100vw;
      height: 773.8vw; /// 2476/320 = 7.7375

      &__members {
        /* Box-model */
        width: 90vw; /// 288/320 = 0.9
        margin-top: 7.3vw; /// 23.5/320 = 0.0734375

        /* Grid Box */
        grid-template-columns: 1fr;
        column-gap: unset;
        row-gap: 7.5vw; /// 24/320 = 0.075

        &__quote {
          /* Box-model */
          display: none;
        }
      }

      .team__member-card {
        /* Positioning */
        position: relative;

        /* Box-model */
        width: 90vw; /// 288/320 = 0.9
        height: 142.8vw; /// 457/320 = 1.428125

        &__container {
          /* Positioning */
          top: 7.5vw; /// 24/320 = 0.075

          /* Box-model */
          width: 75vw; /// 240/320 = 0.75
          height: 122vw; /// 390.5/320 = 1.2203125
        }

        &__avatar {
          /* Box-model */
          width: 75vw; /// 240/320 = 0.75
          height: 97.8vw; /// 313/320 = 0.978125
        }

        &__name {
          /* Box-model */
          margin-top: 0.8vw; /// 2.4/320 = 0.0075

          /* Typography */
          font-size: 6.9vw; /// 22/320 = 0.06875
          line-height: 8.8vw; /// 28/320 = 0.0875
        }

        &__job-title {
          /* Box-model */
          margin-top: 8.1vw; /// 26/320 = 0.08125

          /* Typography */
          font-size: 3.8vw; /// 12/320 = 0.0375
          line-height: 6.3vw; /// 20/320 = 0.0625
        }

        &__empty {
          /* Box-model */
          width: 75vw; /// 240/320 = 0.75
          height: 122vw; /// 390.5/320 = 1.2203125

          /* Typography */
          font-size: 9.1vw; /// 29/320 = 0.090625
          line-height: 122vw; /// 390.5/320 = 1.2203125
        }
      }
    }

    & .element-position {
      /* Positioning */
      position: relative;
      top: -25vw;
    }
  }
}
