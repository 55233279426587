@import "../../styles/style";

.service {
  /* Box-model */
  display: flex;
  width: 100%;
  margin-bottom: 170px;

  /* Flexible Box */
  flex-direction: column;
  align-items: center;

  &:nth-child(even) {
    .service__content-container {
      /* Box-model */
      height: 795px;

      /* Flexible Box */
      flex-direction: row-reverse;
    }
  }

  &__content-container {
    /* Box-model */
    display: flex;
    width: 100%;
    height: 497px;
    margin-top: 71px;
  }

  &__image {
    /* Box-model */
    display: flex;

    /* Flexible Box */
    align-items: center;

    img {
      /* Box-model */
      width: 100%;
    }
  }

  &__information-container {
    /* Box-model */
    display: flex;
    width: 500px;

    /* Flexible Box */
    flex-direction: column;
    justify-content: center;
  }

  &__name {
    /* Box-model */
    margin-bottom: 30px;

    /* Typography */
    font-size: 48px;
    line-height: 52px;

    &--red {
      /* Typography */
      color: $red-text;
    }

    &--blue {
      /* Typography */
      color: $blue-faceta;
    }
  }

  &__description {
    /* Box-model */
    width: 402px;
    margin-bottom: 36px;

    /* Typography */
    line-height: 25px;
    color: $black-text;
  }

  &__downloadButton {
    /* Box-model */
    width: 261px;
    height: 50px;

    /* Visual */
    border-radius: 4px;
    border: 1px solid $gray-clio-button;

    &:hover {
      /* Visual */
      border-color: $red-text;
      background: $red-text;
    }
  }

  &__downloadButton-link {
    /* Box-model */
    display: flex;
    width: 100%;
    height: 100%;

    /* Flexible Box */
    justify-content: space-evenly;
    align-items: center;

    /* Visual */
    cursor: pointer;

    /* Typography */
    text-decoration: none;
    font-size: 18px;
    line-height: 25px;
    color: $gray-clio-button;

    img {
      /* Box-model */
      width: 23px;
      height: 23px;
    }

    &:hover {
      /* Typography */
      color: $white-100;

      img {
        /* Visual */
        filter: brightness(100);
      }
    }
  }

  &__features {
    /* Box-model */
    display: flex;
    width: 100%;
    height: 180px;

    /* Flexible Box */
    justify-content: space-between;

    .features {
      &__link {
        /* Box-model */
        display: flex;
        width: 320px;

        /* Flexible Box */
        flex-direction: column;
        align-items: center;
        justify-content: center;

        /* Visual */
        border-right: 1px solid $gray-content;

        &:last-child {
          /* Visual */
          border-right: none;
        }
      }

      &__icon {
        /* Box-model */
        width: 100px;
        height: 80px;
        margin-bottom: 27px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      &__name {
        /* Typography */
        font-size: 21px;
        line-height: 26px;

        &--red {
          /* Typography */
          color: $red-text;
        }

        &--blue {
          /* Typography */
          color: $blue-faceta;
        }
      }

    }
  }
}

@media (max-width: 1365px) {
  .service {
    /* Box-model */
    width: 91.6vw; /// 293/320 = 0.915625
    margin: 0 auto 29.4vw auto; /// 94/320 = 0.29375

    /* Flexible Box */
    flex-direction: column;
    align-items: center;

    &:nth-child(even) {
      .service__content-container {
        /* Box-model */
        height: unset;

        /* Flexible Box */
        flex-direction: column;
      }
    }

    &__content-container {
      /* Box-model */
      width: 100%;
      height: unset;
      margin-top: 3.1vw; /// 10/320 = 0.03125

      /* Flexible Box */
      flex-direction: column;
      align-items: center;
    }

    &__image {
      /* Box-model */
      width: 95%;
      margin-bottom: 5.6vw; /// 18/320 = 0.05625

      img {
        /* Box-model */
        width: 100%;
        height: 100%;
      }
    }

    &__information-container {
      /* Box-model */
      display: flex;
      width: 100%;
    }

    &__name {
      /* Box-model */
      width: 100%;
      margin-bottom: 2.5vw; /// 8/320 = 0.025

      /* Typography */
      line-height: 12.5vw; /// 40/320 = 0.125
      font-size: 10.3vw; /// 33/320 = 0.103125
    }

    &__description {
      /* Box-model */
      width: 100%;
      margin-bottom: 10.6vw; /// 34/320 = 0.10625

      /* Typography */
      font-size: 5vw; /// 16/320 = 0.05
      line-height: 7.8vw; /// 25/320 = 0.078125
      color: $black-text;
    }

    &__downloadButton {
      /* Box-model */
      width: 68.8vw; /// 220/320 = 0.6875
      height: 12.5vw; /// 40/320 = 0.125
      display: flex;
      margin-bottom: 15.9vw; /// 51/320 = 0.159375

      /* Flexible Box */
      align-self: center;
      justify-content: center;
    }

    &__downloadButton-link {
      span {
        /* Typography */
        font-size: 5vw; /// 16/320 = 0.05
        line-height: 6.9vw; /// 22/320 = 0.06875
        text-align: center;
      }

      img {
        /* Box-model */
        width: 5vw; /// 16/320 = 0.05
        height: 5.3vw; /// 17/320 = 0.053125
      }
    }

    &__features {
      /* Box-model */
      width: 79.7vw; /// 255/320 = 0.796875
      height: 75.3vw; /// 241/320 = 0.753125

      /* Flexible Box */
      flex-wrap: wrap;
      justify-content: space-evenly;
    }

    .features {
      &__link {
        /* Box-model */
        width: 50%;
        margin-bottom: 8.4cw; /// 27/320 = 0.084375

        &:nth-child(odd) {
          /* Visual */
          border-right: 0.3vw solid $gray-content; /// 1/320 = 0.003125
        }

        &:nth-child(even) {
          /* Visual */
          border-right: none;
        }
      }

      &__link:last-child {
        & div:last-child {
          /* Box-model */
          width: 20vw;
        }
      }

      &__icon {
        /* Box-model */
        width: 15.6vw; /// 50/320 = 0.15625
        height: 12.5vw; /// 40/320 = 0.125

        img {
          /* Box-model */
          width: 100%;
          height: 100%;
        }
      }

      &__name {
        /* Box-model */
        width: 31.3vw; /// 100/320 = 0.3125

        /* Typography */
        font-size: 5vw; /// 16/320 = 0.05
        line-height: 6.3vw; /// 20/320 = 0.0625
        text-align: center;
      }
    }
  }
}
