@import "../../styles/_style";

.arisan-info {
  /* Positioning */
  z-index: z-index(contents);

  /* Box-model */
  display: flex;
  width: 1366px;
  margin: 0 auto;

  /* Flexible Box */
  flex-direction: column;

  /* Typography */
  color: $black-text;

  * {
    box-sizing: border-box;
  }
}

@media (max-width: 1365px) {
  .arisan-info {
    /* Box-model */
    width: 100%;
  }
}
