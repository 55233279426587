@import "../../styles/style";

.navbar {
  a {
    /* Typography */
    text-decoration: none;

    /* Visual */
    user-select: none;
  }

  & .disabled {
    /// Make the unusable parts invisible.
    /* Visual */
    visibility: hidden;
  }

  /* Positioning */
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: z-index(navbar);

  /* Box-model */
  display: flex;
  height: 97px;

  /* Flexible Box */
  justify-content: center;

  &__nav {
    /* Box-model */
    display: flex;
    min-width: 1366px;

    /* Flexible Box */
    align-items: center;
  }

  &__logo {
    /* Box-model */
    width: 138px;
    margin-right: 212px;
    margin-left: 70px;

    &--link-disabled {
      /* Typography */
      pointer-events: none;
    }
  }

  &__nav-item {
    /* Positioning */
    position: relative;

    /* Typography */
    font-size: 18px;
    line-height: 97px;
    letter-spacing: 0.81px;

    /* Visual */
    background: $black-text-invisible;

    &--link {
      /* Box-model */
      display: block;
      padding: 0 33px;

      /* Typography */
      color: $white-100;
    }

    & .sub-nav {
      /* Box-model */
      display: block;

      /* Visual */
      background: $black-text-invisible;
      visibility: hidden;
    }

    & .navbar__nav-item-text {
      /* Box-model */
      padding-bottom: 7px;

      /* Visual */
      border-bottom: 3px solid;
      border-color: $red-background-invisible;
    }
  }

  &__nav-item--visible {
    /* Positioning */
    position: relative;

    /* Typography */
    font-size: 18px;
    line-height: 97px;
    letter-spacing: 0.81px;

    /* Visual */
    background: $black-text;
    transition: 1s;

    /* Typography */
    cursor: pointer;

    & .sub-nav {
      /* Box-model */
      display: block;

      /* Visual */
      background: $black-text;
      transition: 1s;

    }

    & .navbar__nav-item-text {
      /* Box-model */
      padding-bottom: 7px;

      /* Visual */
      border-bottom: 3px solid;
      border-color: $red-background-invisible;

      &--visible {
        border-color: $red-background;
        transition: 1s;
      }
    }
  }
}

.navbar-home-background {
  /* Visual */
  background: $black-home-navbar-background-invisible;

  /* Typography */
  &__visible {
    background: $black-100;
    opacity: 0.8;
    transition: 1.5s;
  }
}

.navbar-default-background {
  /* Visual */
  background: $black-100;
}

.m-navbar {
  /* Box-model */
  display: none; /// hide the mobile navbar
}

@media (max-width: 1365px) {
  .navbar {
    /* Box-model */
    display: none; /// hide the desktop navbar
  }

  .m-navbar {
    /* Box-model */
    display: block;
  }
}
