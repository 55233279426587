/* white */
$white-100: #ffffff;
$white-100-invisible: rgba($color: $white-100, $alpha: 0);
$white-home-slogan: #FFFCFC;

/* Black */
$black-100: #000000;
$black-background: #000000;
$black-clio-header-background: rgb(27, 23, 21);
$black-key-features-background: #191919;
$black-contact-form-background: rgba($color: $black-100, $alpha: 0.85);
$black-home-navbar-background-invisible: rgba($color: $black-100, $alpha: 0);
$black-text: #232323;
$black-text-invisible: rgba($color: $black-text, $alpha: 0);
$black-text-clio: #333333;
$black-video-player: #214170;;

/* Red */
$red-background: #e10012;
$red-background-invisible: rgba($color: $red-background, $alpha: 0);;
$red-text: #e60012;
$red-built-in-line: #E30012;

/* Blue */
$blue-faceta: #187FC4;
$blue-home: #187FC4;
$blue-faceta-border: #1E77B3;

/* Gray */
$gray-about-us-paragraph: #A3A3A3;
$gray-application-background: #F4F4F4;
$gray-arisan-info: #F4F4F4;
$gray-cases-type: #777777;
$gray-clio-button: #707070;
$gray-content: #CCCCCC;
$gray-contact-us-address: #7A7A7A;
$gray-contact-us-button: #A3A3A3;
$gray-contact-us-radio: #808080;
$gray-dropdown: rgba(0, 0, 0, 0.03);
$gray-clio-background: #EDEDEE;
$gray-faceta-header-background: #F0F0F1;
$gray-peer-header-background: #F0F0F1;

/* Purple */
$purple-peer: #7952B3;

/* else */
$building-extend-color: rgb(33, 64, 98);
$about-us-building-extend-color: #272c35;
$input-box-color: #f4f4f4;
$input-focus-color: #fcfcfc;
$input-focus-shadow-color: #e8e8e8;
