@import "../../styles/style";

.arisan-info {
  .contact-us-section {
    /* Positioning */
    z-index: z-index(contents);

    /* Box-model */
    width: 100%;
    height: 581px;

    &__title {
      /* Box-model */
      width: 100%;
      height: 181px;
      padding-top: 88px;
    }

    &__content {
      /* Box-model */
      display: flex;
      width: 100%;
      height: 400px;

      /* Flexible Box */
      justify-content: center;
      align-items: center;
    }

    &__form-container {
      /* Box-model */
      display: flex;
      width: 857px;
      margin: 0 auto;

      /* Flexible Box */
      justify-content: space-between;

      & .contact-information {
        /* Visual */
        user-select: none;

        &__image {
          /* Box-model */
          margin-bottom: 10px;
        }

        &__button {
          /* Box-model */
          display: flex;
          height: 50px;

          /* Flexible Box */
          align-items: center;
          justify-content: center;

          /* Visual */
          background: $gray-contact-us-button;
          border-radius: 4px;

          &--text {
            /* Typography */
            font-size: 21px;
            line-height: 30px;
            color: $white-100;
          }
        }

        &:hover {
          /* Typography */
          cursor: pointer;

          & .contact-information__button {
            /* Visual */
            background: $red-text;
          }
        }
      }
    }
  }
}

@media (max-width: 1365px) {
  .arisan-info {
    .contact-us-section {
      /* Box-model */
      height: 101.3vw; /// 324/320 = 1.0125

      &__title {
        /* Box-model */
        height: 33.4vw; /// 107/320 = 0.334375
        padding-top: 15vw; /// 48/320 = 0.15
      }

      &__content {
        /* Box-model */
        height: 67.8vw; /// 217/320 = 0.678125
      }

      &__form-container {
        /* Box-model */
        width: 93.8vw; /// 300/320 = 0.9375
      }

      &__content {
        /* Box-model */
        width: 100%;
        display: flex;

        /* Flexible Box */
        justify-content: space-between;

        .contact-information {
          /* Box-model */
          height: 35.3vw; /// 113/320 = 0.353125
          width: 27.5vw; /// 88/320 = 0.275

          &__image {
            /* Box-model */
            width: 100%;
            height: 25.9vw; /// 83/320 = 0.259375
            margin-bottom: 1.6vw; /// 5/320 = 0.015625

            img {
              /* Box-model */
              width: 100%;
              height: 100%;

              /* Visual */
              border-radius: 1.3vw; /// 4/320 = 0.0125
            }
          }

          &__button {
            /* Box-model */
            width: 100%;
            height: 7.8vw; /// 25/320 = 0.078125

            /* Visual */
            border-radius: 1.3vw; /// 4/320 = 0.0125

            &--text {
              /* Typography */
              font-size: 5vw; /// 16/320 = 0.05
              line-height: 7.8vw; /// 25/320 = 0.078125
            }
          }
        }
      }
    }
  }
}
