@import "../../styles/style";

.home-background {
  /* Positioning */
  position: relative;
  top: -97px;

  /* Box-model */
  width: 100%;

  &__video-section {
    /* Positioning */
    position: relative;
    z-index: z-index(background); /// -2

    /* Box-model */
    width: 100%;
    height: 992px;
  }

  &__video-blue-cover {
    /* Positioning */
    position: absolute;

    /* Box-model */
    width: 100%;
    height: 100%;

    /* Visual */
    background: $black-video-player;
    opacity: 0.6;
  }

  &__video-container {
    /* Box-model */
    width: 100%;
    height: 100%;
  }

  &__video {
    /* Box-model */
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  &__reimagined-section {
    /* Positioning */
    position: relative;
    z-index: z-index(background); /// -2

    /* Box-model */
    width: 100%;
    height: 470px;

    &--block {
      /* Position */
      position: absolute;
      top: -192px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: z-index(home-background-triangle); /// 2

      /* Box-model */
      height: 100%;
      width: 100%;

      /* Visual */
      background: $black-text;
      transform: skewY(-5deg);
      transform-origin: 50% 0%;
    }
  }

  &__problems-section {
    /* Positioning */
    position: relative;
    z-index: z-index(background); /// -2

    /* Box-model */
    width: 100%;
    height: 2207px;

    &--block {
      /* Positioning */
      position: absolute;
      top: -192px;

      /* Box-model */
      height: 100%;
      width: 100%;

      /* Visual */
      background: $black-100;
      transform: skewY(-5deg);
      transform-origin: 50% 0%;
    }
  }

  &__slogan-section {
    /* Positioning */
    position: relative;
    z-index: z-index(background); /// -2

    /* Box-model */
    width: 100%;
    height: 883px;

    &--block {
      /* Positioning */
      position: absolute;
      top: -192px;

      /* Box-model */
      height: 100%;
      width: 100%;

      /* Visual */
      background: $black-text;
      transform: skewY(-5deg);
      transform-origin: 50% 0%;
    }

    &--red {
      /** Using position: absolute and margin auto to move position to the center
        * and use right: fix to need position.
        */
      /* Positioning */
      position: absolute;
      top: 650px;
      left: 0;
      right: -500px;
      bottom: 0;
      z-index: z-index(home-background-decoration); /// -1

      /* Box-model */
      height: 50px;
      width: 641px;
      margin: 0 auto 0;

      /* Visual */
      background: $red-text;
      transform: skewY(-5deg);
      transform-origin: 50% 0%;
    }
  }

  &__services-section {
    /* Positioning */
    position: relative;
    z-index: z-index(background);

    /* Box-model */
    width: 100%;
    height: 2570px;

    &--block {
      /* Positioning */
      position: absolute;
      top: -192px;

      /* Box-model */
      width: 100%;
      height: 100%;

      /* Visual */
      background: $white-100;
      transform: skewY(-5deg);
      transform-origin: 50% 0%;
    }
  }

  &__serverless-section {
    /* Positioning */
    position: relative;
    z-index: z-index(background);

    /* Box-model */
    width: 100%;
    height: 1126px;

    &--block {
      /* Positioning */
      position: absolute;
      top: -192px;

      /* Box-model */
      width: 100%;
      height: 100%;

      /* Visual */
      background: $black-text;
      transform: skewY(-5deg);
      transform-origin: 50% 0%;
    }
  }
}

@media (max-width: 1365px) {
  .home-background {
    /* Positioning */
    top: 0px;

    &__video-section {
      /* Box-model */
      height: 171.6vw; /// 549/320 = 1.715625
    }

    &__video {
      /* Box-model */
      object-fit: cover;
    }

    &__reimagined-section {
      /* Box-model */
      height: 68.8vw; /// 220/320 = 0.6875

      /* Visual */
      background: $black-text;

      &::before {
        /* Positioning */
        position: absolute;

        /* Box-model */
        display: block;
        width: 100%;
        height: 14vw;

        /* Visual */
        background-color: $black-text;
        transform: skewY(-8deg);
        transform-origin: top left;
        content: '';
      }

      &--block {
        /* Box-model */
        display: none;
      }
    }

    &__problems-section {
      /* Box-model */
      height: 550vw; /// 1760/320 = 5.5

      /* Visual */
      background: $black-100;

      &::before {
        /* Positioning */
        position: absolute;

        /* Box-model */
        display: block;
        width: 100%;
        height: 14vw;

        /* Visual */
        background-color: $black-100;
        transform: skewY(-8deg);
        transform-origin: top left;
        content: '';
      }

      &--block {
        /* Box-model */
        display: none;
      }
    }

    &__slogan-section {
      /* Box-model */
      height: 209.1vw; /// 669/320 = 2.090625

      /* Visual */
      background: $black-text;

      &::before {
        /* Positioning */
        position: absolute;

        /* Box-model */
        display: block;
        width: 100%;
        height: 14vw;

        /* Visual */
        background-color: $black-text;
        transform: skewY(-8deg);
        transform-origin: top left;
        content: '';
      }

      &--block {
        /* Box-model */
        display: none;
      }

      &--red {
        /* Box-model */
        display: none;
      }
    }

    &__services-section {
      /* Box-model */
      height: 652.2vw; /// 2087/320 = 6.521875

      /* Visual */
      background: $white-100;

      &::before {
        /* Positioning */
        position: absolute;

        /* Box-model */
        display: block;
        width: 100%;
        height: 14vw;

        /* Visual */
        background-color: $white-100;
        transform: skewY(-8deg);
        transform-origin: top left;
        content: '';
      }

      &--block {
        /* Box-model */
        display: none;
      }
    }

    &__serverless-section {
      /* Box-model */
      height: 452.2vw; /// 1447/320 = 4.521875

      /* Visual */
      background: $black-text;

      &::before {
        /* Positioning */
        position: absolute;

        /* Box-model */
        display: block;
        width: 100%;
        height: 14vw;

        /* Visual */
        background-color: $black-text;
        transform: skewY(-8deg);
        transform-origin: top left;
        content: '';
      }

      &--block {
        /* Box-model */
        display: none;
      }
    }
  }
}
