@import "../../../styles/style";

.m-navbar {
  /* Positioning */
  z-index: z-index(navbar);
  position: fixed;

  /* Box-model */
  width: 100vw;
  height: 20vw; /// 64px/320 = 0.2
  min-width: 320px;

  /* Visual */
  box-sizing: border-box;

  * {
    /* Visual */
    box-sizing: border-box;
  }

  &__menu-toggler {
    /* Box-model */
    display: none; /// make the checkbox invisible

    &:checked~.m-navbar__menu {
      /* Box-model */
      display: none; /// when the checkbox is checked, hide the menu
    }
  }

  &__nav {
    /* Box-model */
    display: grid;
    width: 100%;
    height: 100%;

    /* Grid Box */
    grid-template-columns: 5vw 9.1vw auto 9.1vw 5vw; /// 16 29 auto 29 16
    grid-template-rows: 1fr;
    justify-items: center;
    align-items: center;

    /* Visual */
    background: $black-background;
  }

  &__hamburger {
    /* Box-model */
    display: flex;
    width: 9.1vw; /// 29/320 = 0.090625
    height: 9.1vw; /// 29/320 = 0.090625

    /* Grid Item */
    grid-column: 2/3;

    /* Flexible Box */
    justify-content: center;
    align-items: center;

    img {
      /* Box-model */
      width: 100%;
      height: 100%;
    }
  }

  &__logo {
    /* Box-model */
    display: flex;
    width: 35.6vw; /// 114/320 = 0.35625
    height: 9.1vw; /// 29/320 = 0.090625

    /* Flexible Box */
    justify-content: center;
    align-items: center;

    a {
      /* Box-model */
      width: 100%;
      height: 100%;
    }

    img {
      /* Box-model */
      width: 100%;
      height: 100%;
    }
  }

  &__earth {
    /* Box-model */
    display: flex;
    width: 9.1vw;
    height: 9.1vw;

    /* Grid Item */
    grid-column: 4/5;

    /* Flexible Box */
    justify-content: center;
    align-items: center;

    img {
      /* Box-model */
      width: 100%;
      height: 100%;
    }
  }

  &__menu {
    /* Box-model */
    width: 100vw; /// 320px
    height: 100vh;

    /* Visual */
    background: $black-100;
    border-top: 0.2vw solid $gray-content; /// 0.5/320 = 0.0015625

    .menu {
      &__section {
        /* Box-model */
        margin-left: 18.8vw; /// 60/320 = 0.1875
      }

      &__title,
      &__item {
        /* Box-model */
        display: block;
        padding-top: 2.8vw; /// 9/320 = 0.028125
        padding-bottom: 2.2vw; /// 7/320 = 0.021875
      }

      &__title {
        /* Visual */
        border-top: 0.2vw solid $gray-content;
        border-bottom: 0.2vw solid $gray-content;

        /* Typography */
        color: $white-100;

        &--top {
          /* Visual */
          border-top: none;
        }
      }

      &__item {
        /* Box-model */
        margin-left: 9.4vw; /// 30/320 = 0.09375

        /* Visual */
        border-bottom: 0.2vw solid $gray-content;

        /* Typography */
        color: $gray-about-us-paragraph;

        &--last {
          /* Visual */
          border-bottom: none;
        }
      }
    }
  }
}
