@import "../../styles/style";

.faceta-background {
  /* Box-model */
  width: 4366px;
  z-index: z-index(background);

  /* Visual */
  background-color: $white-100;

  &__header {
    /* Positoning */
    position: relative;

    /* Box-model */
    height: 1726px;
    margin-bottom: 717px;

    &--blue {
      /* Positioning */
      z-index: z-index(background-below);

      /* Box-model */
      /* right end height */
      width: 100%;
      height: 668px;

      /* Visual */
      background: $blue-faceta;
      transform: skewY(7.9deg);
      transform-origin: 50% 0%;
    }

    &--image {
      /* Positioning */
      position: absolute;
      /* Align the middle to the height */
      top: -356px;

      /* Box-model */
      /* right end height */
      width: 100%;
      height: 1110px;

      /* Visual */
      background: $gray-faceta-header-background;
      transform: skewY(-8deg);
      transform-origin: 50% 0%;
    }
  }

  &__key-features {
    /* Positioning */
    position: relative;

    /* Box-model */
    width: 100%;
    height: 878px;

    &--block {
      /* Box-model */
      width: 100%;
      height: 782px;

      /* Visual */
      background: $blue-faceta;
      transform: skewY(-8deg);
      transform-origin: 50% 0%;
    }

    &--black {
      /** Using position: absolute and margin auto to move position to the center
      and use right: fix to need position  */
      /* Position */
      position: relative;
      top: -48px;
      left: 312px;
      right: 0;
      bottom: 0;
      z-index: z-index(background-below);

      /* Box-model */
      height: 41px;
      width: 641px;
      margin: 0 auto 0;

      /* Visual */
      background: $black-100;
      transform: skewY(-8deg);
      transform-origin: 50% 0%;
    }
  }

  &__applications {
    /* Box-model */
    width: 100%;
    height: 4923px;
  }

  &__download {
    /* Box-model */
    width: 100%;
    height: 204px;
  }
}

@media (max-width: 1365px) {
  .faceta-background {
    /* Box-model */
    width: 100vw;

    &__header {
      /* Box-model */
      height: unset;
      margin-bottom: 358.3vw;
      overflow: hidden;

      &--blue {
        /* Positioning */
        position: relative;

        /* Box-model */
        width: 100vw;
        height: 262.5vw; /// 840/320 = 2.625

        /* Visual */
        transform: skewY(8deg);
        transform-origin: bottom right;
      }

      &--image {
        /* Box-model */
        display: none;
      }
    }

    &__key-features {
      /* Box-model */
      width: unset;
      height: unset;

      &--block {
        /* Box-model */
        width: 100vw;
        height: 415.6vw; /// 1330/320 = 4.15625

        /* Visual */
        transform-origin: top right;
      }

      &--black {
        /* box-model */
        display: none;
      }
    }

    &__applications {
      /* Box-model */
      width: 100vw;
      height: 1538.4vw; /// 4923/320 = 15.384375
    }

    &__download {
      /* Box-model */
      width: 100vw;
      height: 39.4vw; /// 126/320 = 0.39375
    }
  }
}
