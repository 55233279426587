.app {
  /* Positioning */
  position: relative;

  /* Box-model */
  display: flex;
  overflow: hidden;

  /* Flexible Box */
  flex-direction: column;
}
