@import "../../styles/_style";

.pdf-download {
  /* Box-model */
  display: flex;
  width: 425px;
  height: 50px;

  /* Flexible Box */
  justify-content: space-between;
  align-items: center;

  & a {
    /* Box-model */
    height: 100%;
  }

  &__button {
    /* Box-model */
    display: flex;
    width: 261px;
    height: 100%;
    padding: 0 31px;

    /* Flexible Box */
    justify-content: space-between;
    align-items: center;

    /* Typography */
    border: 1px solid $gray-clio-button;
    border-radius: 4px;
    box-sizing: border-box;
    user-select: none;

    &--text {
      /* Typography */
      color: $gray-contact-us-button;
    }

    &:hover {
      /* Typography */
      cursor: pointer;

      /* Visual */
      background: $red-text;
      border: 1px solid $red-text;

      & .pdf-download__button {
        &--text {
          /* Typography */
          color: $white-100;
        }

        &--icon {
          /* Visual */
          filter: brightness(100);
        }
      }
    }
  }
}

@media (max-width: 1365px) {
  .pdf-download {
    /* Box-model */
    width: unset;
    height: 23.75vw; /// 76/320 = 0.2375
    margin-top: 30vw; /// 96/320 = 0.3

    /* Flexible Box */
    flex-direction: column;

    &__text {
      /* Box-model */
      margin-bottom: 4.4vw; /// 14/320 = 0.04375

      /* Typography */
      font-size: 5vw; /// 16/320 = 0.05
    }

    &__button {
      /* Box-model */
      width: 62.5vw; /// 200/320 = 0.625
      height: 12.5vw; /// 40/320 = 0.125
      padding: 0 4vw;

      &--text {
        /* Typography */
        font-size: 5vw; /// 16/320 = 0.05
      }

      &--icon {
        & img {
          /* Box-model */
          width: 4.7vw; /// 15/320 = 0.046875
        }
      }
    }
  }
}
