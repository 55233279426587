.home {
  /* Box-model */
  display: flex;
  width: 1366px;
  margin: 0 auto;

  /* Flexible Box */
  flex-direction: column;
  align-items: center;
}

@media (max-width: 1365px) {
  .home {
    /* Box-model */
    width: 100vw;
    max-width: 1365px;
  }
}
