@import "../../styles/style";

.application-icon-wrapper {
  /* Box-model */
  display: flex;
  width: 341px;
  height: 341px;

  /* Flexible Box */
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* Visual */
  border-right: 1px solid #EDEDED;
  border-bottom: 1px solid #EDEDED;

  &__image {
    /* Box-model */
    width: 100px;
    height: 100px;
    margin-bottom: 45px;

    img {
      /* Box-model */
      width: 100%;
      height: 100%;
    }
  }

  &__name {
    /* Typography */
    color: $black-text;
  }
}
