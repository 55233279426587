@font-face {
  font-family: arisan-font-light;
  src: url("../font/SourceSansPro-Light.ttf");
}

@font-face {
  font-family: arisan-font-normal;
  src: url("../font/SourceSansPro-Regular.ttf");
}

@font-face {
  font-family: arisan-font-semi-bold;
  src: url("../font/SourceSansPro-SemiBold.ttf");
}

@font-face {
  font-family: arisan-font-bold;
  src: url("../font/SourceSansPro-Bold.ttf");
}

@font-face {
  font-family: arisan-font-black;
  src: url("../font/SourceSansPro-Black.ttf");
}

@font-face {
  font-family: arisan-font-roboto-mono;
  src: url("../font/RobotoMono-VariableFont_wght.ttf");
}

.arisan-body {
  font-family: arisan-font-normal;
  letter-spacing: 0.54px;
  font-size: 16px;
}

.arisan-h1 {
  font-family: arisan-font-semi-bold;
  font-size: 48px;
  line-height: 68px;
}

.arisan-h1-normal {
  font-family: arisan-font-normal, sans-serif;
  font-size: 48px;
  line-height: 68px;
}

.arisan-h1-s {
  font-family: arisan-font-semi-bold;
  font-size: 33px;
}

.arisan-h2 {
  font-family: arisan-font-semi-bold;
  font-size: 30px;
  line-height: 40px;
}

.arisan-h2-normal {
  font-family: arisan-font-normal, sans-serif;
  font-size: 30px;
  line-height: 40px;
}

.arisan-h2-s {
  font-family: arisan-font-semi-bold;
  font-size: 22px;
}

.arisan-h3 {
  font-family: arisan-font-semi-bold;
  font-size: 21px;
  letter-spacing: 0.46px;
}

.arisan-h3-normal {
  font-family: arisan-font-normal, sans-serif;
  font-size: 21px;
  line-height: 30px;
}

.arisan-h3-s {
  font-family: arisan-font-semi-bold;
  font-size: 18px;
  letter-spacing: 0.14px;
}

.arisan-footnote {
  font-family: arisan-font-semi-bold;
  font-size: 11px;
  letter-spacing: 0.54px;
}

.arisan-footnote-height {
  font-family: arisan-font-semi-bold;
  font-size: 11px;
  letter-spacing: 0.54px;
  line-height: 3.8;
}

.arisan-bold {
  font-family: arisan-font-bold;
}

.arisan-roboto-mono {
  font-family: arisan-font-roboto-mono, monospace;
  font-size: 115px;
  line-height: 151px;
}

@media (max-width: 1365px) {
  .arisan-h1 {
    font-size: 10.3vw; /// 33/320 = 0.103125
    line-height: 12.5vw; /// 40/320 = 0.125
  }

  .arisan-h1-normal {
    font-size: 10.3vw; /// 33/320 = 0.103125
    line-height: 12.5vw; /// 40/320 = 0.125
  }

  .arisan-h2 {
    font-size: 6.9vw; /// 22/320 = 0.06875
    line-height: 8.8vw; /// 28/320 = 0.0875
  }

  .arisan-h2-normal {
    font-size: 6.9vw; /// 22/320 = 0.06875
    line-height: 8.8vw; /// 28/320 = 0.0875
  }

  .arisan-h3-normal {
    font-size: 5.6vw; /// 18/320 = 0.05625
    line-height: 8.1vw; /// 26/320 = 0.08125
  }
}
