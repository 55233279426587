@import "../../styles/style";

.background {
  /* Positioning */
  position: absolute;
  top: 97px;

  /* Box-model */
  display: flex;
  width: 100vw;

  /* Flexible Box */
  flex-direction: column;
  align-items: center;
}

@media (max-width: 1365px) {
  .background {
    top: 20vw; /// 64/320 = 0.2
  }
}
