@import "../../styles/style";

.peer-background {
  /* Positioning */
  position: relative;
  top: -97px;
  z-index: z-index(background-below);

  /* Visual */
  background: $white-100;

  &__header {
    /* Positioning */
    position: relative;

    /* Box-model */
    width: 100vw;
    height: 948px;
  
    &__image {
      /* Positioning */
      position: absolute;
      top: -96px;
  
      /* Box-model */
      width: 100vw;
      height: 948px;
  
      /* Visual */
      background: $gray-peer-header-background;
      transform: skewY(-8deg);
    }

    &__gray {
      /* Positioning */
      position: absolute;
      top: 0;
      
      /* Box-model */
      width: 100vw;
      height: 50%;

      /* Visual */
      background: $gray-peer-header-background;
    }

    &__purple {
      /* Positioning */
      position: absolute;
      top: -96px;
      z-index: z-index(background);

      /* Box-model */
      width: 100vw;
      height: 858px;

      /* Visual */
      background: $purple-peer;
      transform: skewY(8deg);
    }
  }

  &__peer-series {
    /* Positioning */
    position: relative;
    z-index: z-index(background-below);

    /* Box-model */
    width: 100vw;
    height: 689px;

    /* Visual */
    background: $white-100;
  }

  &__key-features {
    /* Box-model */
    width: 100vw;
    height: 943px;

    /* Visual */
    background: $white-100;
  }

  &__infrastructure {
    /* Positioning */
    position: relative;

    /* Box-model */
    width: 100vw;
    height: 1324px;

    /* Visual */
    background: $white-100;

    &__gray {
      /* Positioning */
      position: absolute;
      top: -96px;

      /* Box-model */
      width: 100vw;
      height: 1324px;

      /* Visual */
      background: $gray-peer-header-background;
      transform: skewY(-8deg);
    }
  }

  &__clio-cloud-platform {
    /* Box-model */
    width: 100vw;
    height: 881px;

    /* Visual */
    background: $white-100;
  }

  &__hardware-products {
    /* Box-model */
    width: 100vw;
    height: 629px;

    /* Visual */
    background: $white-100;
  }

  &__download {
    /* Box-model */
    width: 100vw;
    height: 170px;

    /* Visual */
    background: $white-100;
  }
}

@media (max-width: 1365px) {
  .peer-background {
    /* Positioning */
    top: -20vw; /// 64/320 = 0.2

    &__header {
      /* Box-model */
      height: 255.9vw; /// 819/320 = 2.559375

      &__image {
        /* Box-model */
        display: none;
      }

      &__gray {
        /* Box-model */
        display: none;
      }

      &__purple {
        /* Positioning */
        top: -8vw; /// 25.5/320 = 0.0796875

        /* Box-model */
        height: 255.9vw; /// 819/320 = 2.559375
      }
    }

    &__peer-series {
      /* Box-model */
      height: 276.3vw; /// 884/320 = 2.7625
    }

    &__key-features {
      /* Box-model */
      height: 489.4vw; /// 1566/320 = 4.89375
    }

    &__infrastructure {
      /* Box-model */
      height: 235.6vw; /// 754/320 = 2.35625

      &__gray {
        /* Positioning */
        top: -7vw; /// 22.5/320 = 0.0703125

        /* Box-model */
        height: 236.9vw; /// 758/320 = 2.36875
      }
    }

    &__clio-cloud-platform {
      /* Box-model */
      height: 668.8vw; /// 2140/320 = 6.6875
    }

    &__hardware-products {
      /* Box-model */
      height: 493.1vw; /// 1578/320 = 4.93125
    }

    &__download {
      /* Box-model */
      height: 63.3vw; /// 202.5/320 = 0.6328125
    }
  }
}
