@import '../../styles/style';

.header-block {
  /* Positioning */
  position: relative;

  /* Box-model */
  width: 1366px;
  height: 388px;

  &__title {
    /* Positioning */
    position: absolute;
    top: 156px;
    left: 50%;
    z-index: z-index(header-title);

    /* Visual */
    transform: translateX(-50%);

    /* Typography */
    font-size: 30px;
    letter-spacing: 0.27px;
    color: $white-100;
  }

  &__triangle {
    /* Positioning */
    position: absolute;
    z-index: z-index(triangle);

    /* Box-model */
    width: 100%;
    height: 100%;

    /* Visual */
    background: $red-background;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 50%);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 50%);
  }

  &__image {
    /* Positioning */
    z-index: z-index(building-image);

    /* Box-model */
    width: 100%;
    height: 363.6px;
    
    /* Visual */
    clip-path: polygon(0 0, 100% 0, 100% 50%, 0 100%);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 50%, 0 100%);
    

    & img {
      /* Box-model */
      height: 100%;
    }
  }
}

.side-extend {
  &__left {
    /* Positioning */
    position: absolute;
    right: calc(1366px + (100vw - 1366px) / 2 - 1px);

    /* Box-model */
    width: 1394px;
    height: 194px;

    /* Visual */
    background: $red-background;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 0); /// 8deg
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 0);
  }

  &__right {
    /* Positioning */
    position: absolute;
    left: calc(1366px + (100vw - 1366px) / 2 - 1px);

    /* Box-model */
    width: 1394px;
    height: calc(388px + 194px);

    /* Visual */
    background: $red-background;
    clip-path: polygon(0 0,100% 0, 100% 100%, 0 66.7%); /// 8deg
    -webkit-clip-path: polygon(0 0,100% 0, 100% 100%, 0 66.7%);
  }

  &__building {
    /* Positioning */
    position: absolute;
    right: calc(1366px + (100vw - 1366px) / 2 - 1px);
    z-index: z-index(building-image);

    /* Box-model */
    width: 1394px;
    height: 560px;

    /* Visual */
    background: $building-extend-color;
    clip-path: polygon(0 0, 100% 0, 100% 64.9%, 0 100%); /// 8deg
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 64.9%, 0 100%);
  }
}

@media (max-width: 1365px) {
  .header-block {
    /* Positioning */
    top: 20vw;

    /* Box-model */
    width: 100vw;
    height: 34vw; /// 111/320=0.346875

    &__title {
      /* Positioning */
      top: 9vw; /// 29/320=0.090625
  
      /* Typography */
      font-size: 6.88vw; /// 22/320=0.06875
    }

    &__triangle {
      /* Visual */
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 58.8%); /// 8deg
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 58.8%);
    }

    &__image {
      /* Box-model */
      height: 30.6vw; /// 162/320=0.50625 - 20vw(navbar height)

      /* Visual */
      clip-path: polygon(0 0, 100% 0, 100% 54.2%, 0 100%); /// 8deg
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 54.2%, 0 100%);
    }
  }
}
