$z-index: (
  background-below: -3,
  background: -2,
  building-image: -1,
  image-under-content: -1,
  home-background-decoration: -1,
  triangle-bottom: -1,
  triangle: 1,
  home-common-problem: 1,
  impression: 1,
  video-player: 1,
  home-background-triangle: 2,
  header-title: 2,
  video-information: 2,
  contents: 5,
  navbar: 10,
  contact-form: 11,
);

@function z-index($key) {
  @return map-get($z-index, $key);
}
