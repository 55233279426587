@import "../../styles/style";

.icon-wrapper {
  /* Box-model */
  display: flex;
  width: 250px;
  height: 250px;

  /* Flexible Box */
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* Visual */
  border-radius: 50%;
  border: 2px solid $red-text;

  &__image {
    /* Box-model */
    width: 75px;
    height: 75px;
    margin-bottom: 18px;

    img {
      /* Box-model */
      width: 100%;
      height: 100%;
    }
  }

  &__name {
    /* Box-model */
    width: 250px;
    height: 49px;

    /* Typography */
    text-align: center;
    color: $white-100;
  }
}

@media(max-width: 1365px) {
  .icon-wrapper {
    /* Box-model */
    width: 63.4vw; /// 203/320 = 0.634375
    height: 63.4vw; /// 203/320 = 0.634375

    /* Visual */
    border: 0.6vw solid $red-text; /// 2/320 = 0.00625

    &__image {
      /* Box-model */
      width: 15.9vw; /// 51/320 = 0.159375
      height: 15.9vw;
      margin-bottom: 4vw;
    }

    &__name {
      /* Box-model */
      width: 63.4vw; /// 203/320 = 0.634375
    }
  }
}
