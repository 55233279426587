@import "../../styles/style";
$line-height-description: 1.5;

.clio {
  & .what-is-clio {
    /* Positioning */
    z-index: z-index(contents);

    /* Box-model */
    width: 100%;
    margin-top: 1045px;

    &__content {
      /* Box-model */
      display: flex;
      height: 380px;
      margin: 0 112px 84.5px;
    }

    &__content-box {
      /* Box-model */
      padding: 0 53px 0 112px;

      /* Flexible Box */
      flex: 1;

      &--title {
        /* Box-model */
        margin-bottom: 30px;

        /* Typography */
        color: $red-text;
      }

      &--content {
        /* Typography */
        line-height: $line-height-description;
        color: $black-text-clio;
        white-space: pre-line;
      }
    }

    &__image-box {
      /* Flexible Box */
      flex: 1;

      &--text {
        /* Positioning */
        position: relative;
        top: 41px;
        left: 95px;

        /* Typography */
        color: $red-text;
      }
    }
  }

  & .how-it-works {
    /* Box-model */
    margin-top: 194px;
    margin-bottom: 83px;

    &__content {
      /* Box-model */
      display: flex;
      width: 1366px;
      margin: 0 auto;

      /* Flexible Box */
      justify-content: center;
    }
  }

  & .key-features-1 {
    /* Box-model */
    width: 100%;
    padding-top: 100px;
    padding-bottom: 353px;
    margin-top: 98px;

    /* Typography */
    color: $white-100;

    &__overview {
      /* Box-model */
      width: 919px;
      margin: 0 auto;

      & .overview {
        /* Box-model */
        display: flex;

        /* Flexible Box */
        justify-content: space-between;

        &__item {
          /* Box-model */
          display: flex;
          width: 205px;
          height: 314px;

          /* Flexible Box */
          flex-direction: column;
          align-items: center;

          &--icon {
            /* Box-model */
            height: 88px;
            margin-top: 75px;
            margin-bottom: 43px;
          }

          &--text {
            /* Box-model */
            width: 130px;

            /* Typography */
            color: $red-text;
            text-align: center;
          }

          &:nth-child(4) span {
            /* Box-model */
            width: 120px;
          }
        }
      }
    }

    &__monitor {
      /* Box-model */
      margin-top: 85px;

      & .health-monitoring {
        /* Box-model */
        padding-bottom: 132px;

        &__feature {
          /* Box-model */
          width: 382px;
          margin-left: 224px;

          &--icon {
            /* Box-model */
            margin-bottom: 17px;

            & > img {
              /* Box-model */
              width: 200px;
            }
          }

          &--title {
            /* Box-model */
            margin-bottom: 8px;

            /* Typography */
            color: $red-text;
          }

          &--slogan {
            /* Box-model */
            margin-bottom: 30px;
          }

          &--description {
            /* Box-model */
            margin-bottom: 6px;

            /* Typography */
            line-height: $line-height-description;
            color: $gray-content;
          }
        }

        &__impression {
          /* Positioning */
          position: relative;

          /* Box-model */
          width: 1366px;
          height: 619px;

          &--image {
            /* Positoning */
            position: absolute;
            right: 0;
            z-index: z-index(impression);
          }

          &--line {
            /* Positioing */
            position: relative;
            top: 115px;
          }
        }
      }
    }

    &__incident-management {
      & .incident-management {
        /* Positioning */
        position: relative;

        /* Box-model */
        padding-bottom: 213px;

        &__feature {
          /* Box-model */
          width: 415px;
          padding-top: 196px;
          margin-left: 224px;

          &--icon {
            /* Box-model */
            margin-bottom: 36px;

            & > img {
              /* Box-model */
              width: 200px;
            }
          }

          &--title {
            /* Box-model */
            margin-bottom: 10px;

            /* Typography */
            color: $red-text;
          }

          &--slogan {
            /* Box-model */
            margin-bottom: 30px;
          }

          &--description {
            /* Box-model */
            margin-bottom: 15px;

            /* Typography */
            line-height: $line-height-description;
            white-space: pre-line;
            color: $gray-content;
          }

          &--red-line {
            /* Box -modal */
            width: 387px;
            margin-left: 5px;
            margin-bottom: 15px;

            /* Visual */
            border-bottom: 1px solid $red-text;
          }

          &--type-title {
            /* Box-model */
            margin-bottom: 12px;

            /* Typography */
            color: $red-text;
          }

          &--type-category {
            /* Box-model */
            display: flex;
            height: 116px;

            /* Flexible Box */
            flex-direction: column;
            flex-wrap: wrap;

            /* Typography */
            line-height: 1.5;
            color: $gray-cases-type;
          }
        }

        &__impression {
          /* Positioning */
          position: absolute;
          top: 0;
          right: 0;
          z-index: z-index(image-under-content);
        }
      }
    }

    &__live-broadcasting {
      /* Box-model */
      margin-top: 65px;

      & .live-broadcasting {
        /* Positioning */
        position: relative;

        &__feature {
          /* Box-model */
          width: 373x;
          padding-top: 80px;
          margin-left: 224px;

          &--icon {
            /* Box-model */
            margin-bottom: 31px;

            & > img {
              /* Box-model */
              width: 200px;
            }
          }

          &--title {
            /* Box-model */
            margin-bottom: 8px;

            /* Typography */
            color: $red-text;
          }

          &--slogan {
            /* Box-model */
            margin-bottom: 26px;
          }

          &--description {
            /* Typography */
            line-height: $line-height-description;
            white-space: pre-line;
            color: $gray-content;
          }
        }

        &__impression {
          /* Positioning */
          position: absolute;
          top: 0;
          right: 0;
          z-index: z-index(image-under-content);
        }

        &__x1000 {
          /* Box-model */
          padding-top: 84.5px;
          padding-left: 38px;
          padding-bottom: 181px;
        }
      }
    }

    &__video-archive {
      /* Box-model */
      margin-top: 65px;

      & .video-archive {
        /* Positioning */
        position: relative;

        &__impression {
          /* Positioning */
          position: absolute;
          top: 259px;
          left: -141px;
          z-index: z-index(image-under-content);
        }

        &__feature {
          /* Box-model */
          width: 366px;
          padding-top: 131px;
          margin-left: 796px;

          &--icon {
            /* Box-model */
            margin-bottom: 41px;

            & > img {
              /* Box-model */
              width: 200px;
            }
          }

          &--title {
            /* Box-model */
            margin-bottom: 8px;

            /* Typography */
            color: $red-text;
          }

          &--slogan {
            /* Box-model */
            margin-bottom: 8px;
          }

          &--description {
            /* Box-model */
            margin-bottom: 34px;

            /* Typography */
            line-height: $line-height-description;
            letter-spacing: 0px;
            color: $gray-content;
            white-space: pre-line;
          }

          &--format {
            /* Typography */
            line-height: 1.5;
            color: $gray-cases-type;
          }
        }
      }
    }

    &__built-in-services {
      /* Box-model */
      padding-top: 115px;

      & .built-in {
        /* Box-model */
        display: flex;

        /* Flexible Box */
        flex-direction: column;
        align-items: center;

        &__title {
          /* Box-model */
          margin-bottom: 21px;

          /* Typography */
          color: $red-text;
        }

        &__container {
          /* Positioning */
          position: relative;

          /* Box-model */
          width: 911px;
          padding: 47px 102px 86px 23px;

          /* Visual */
          border-top: 2px solid $red-built-in-line;
          border-bottom: 2px solid $red-built-in-line;
          box-sizing: border-box;

          &--text {
            /* Positioning */
            position: absolute;
            left: 51px;
            bottom: 88px;

            & span {
              &:nth-child(1) {
                /* Box-model */
                margin-right: 98px;
              }

              &:nth-child(2) {
                /* Box-model */
                margin-right: 91px;
              }

              &:nth-child(3) {
                /* Box-model */
                margin-right: 73px;
              }
            }
          }
        }
      }
    }
  }

  & .key-features-2 {
    /* Box-model */
    width: 100%;

    &__content-box {
      /* Box-model */
      margin: 0 224px;

      & .section-features {
        /* Box-model */
        display: flex;

        & ~ .section-features {
          /* Box-model */
          margin-top: 86px;
        }

        &.left {
          flex-direction: row-reverse;
        }

        &__icon {
          /* Box-model */
          display: flex;

          /* Flexible Box */
          flex: 1;
          justify-content: center;
          align-items: center;
        }

        &__content-container {
          /* Box-model */
          display: flex;
          padding: 44px 0;

          /* Flexible Box */
          flex: 3;
        }

        &__number {
          /*Box-model */
          display: flex;
          margin-bottom: 2px;

          /* Flexible Box */
          flex: 17;
          flex-direction: column-reverse;

          & img {
            width: 40px;
          }
        }

        &__content-box {
          /* Box-model */
          padding: 17px 76px 0 51px;

          /* Flexible Box */
          flex: 83;

          /* Visual */
          border-left: 1px solid $red-background;
          box-sizing: border-box;

          &--title {
            /* Box-model */
            margin-bottom: 10px;

            /*Typography */
            line-height: 1.5;
            color: $red-text;
          }

          &--content {
            /* Typography */
            line-height: 1.5;
            color: $black-text-clio;

            & p {
              /* Typography */
              font-size: 12px;
            }
          }
        }
      }
    }

    &__image-box {
      &:nth-child(4n + 4) {
        /* Box-model */
        display: flex;

        /* Flexible Box */
        justify-content: flex-end;
      }

      &:nth-child(2n + 6) {
        /* Box-model */
        margin: 85px 0;
      }
    }

    & .carousel__container {
      /* Box-model */
      display: none;
    }
  }

  & .download {
    /* Box-model */
    height: 86px;
    margin-bottom: 86px;
  }
}

.mobile-break {
  /* Box-model */
  display: block;
}

.desktop-break {
  /* Box-model */
  display: none;
}

@media (max-width: 1365px) {
  .clio {
    & .what-is-clio {
      /* Box-model */
      margin-top: 297.8vw; /// 956/320 = 2.9875
      margin-bottom: 43vw;; /// 137.5/320 = 

      &__content {
        /* Box-model */
        height: unset;
        margin: 0;

        /* Flexible Box */
        flex-direction: column-reverse;
        align-items: center;
      }

      &__content-box {
        /* Box-model */
        padding: 0;

        &--title {
          /* Box-model */
          margin-bottom: 2.5vw; /// 8/320 = 0.025

          /* Typography */
          font-size: 10.3vw; /// 33/320 = 0.103125
          line-height: 12.5vw; /// 40/320 = 0.125
        }

        &--content  {
          /* Typography */
          line-height: 7.8vw; /// 25/320 = 0.078125
          font-size: 5vw; /// 16/320 = 0.05
        }
      }

      &__image-box {
        /* Box-model */
        display: flex;
        width: 90vw; /// 288/320 = 0.9
        margin-bottom: 12.5vw; /// 40/320 = 0.125
        
        /* Flexible Box */
        flex-direction: column;
        align-items: center;

        &--text {
          /* Positioning */
          top: 0;
          left: 0;

          /* Box-model */
          margin-top: 4.4vw; /// 14/320 = 0.04375

          /* Typography */
          font-size: 3.8vw; /// 12/320 = 0.0375
        }

        &--image {
          /* Box-model */
          width: 100%;;

          & img {
            /* Box-model */
            width: 100%;
          }
        }
      }
    }

    & .how-it-works {
      /* Box-model */
      margin: 0 0 43.6vw; /// 139.5/320 = 0.4359375

      &__content {
        /* Box-model */
        margin: 0;

        & img {
          /* Box-model */
          width: 100vw;
        }
      }
    }

    & .key-features-1 {
      /* Box-model */
      padding: 0;
      margin: 0;

      &__overview {
        /* Box-model */
        display: none;
      }

      &__monitor {
        /* Box-model */
        margin-top: 8.1vw; /// 26/320 = 0.08125
        margin-bottom: 43.8vw; /// 140/320 = 0.4375

        & .health-monitoring {
          /* Box-model */
          padding: 0;

          &__feature {
            /* Box-model */
            width: 90vw; /// 288/320 = 0.9
            margin: 0 auto;

            &--icon {
              /* Box-model */
              margin-bottom: 7.5vw; /// 24/320 = 0.075

              & > img {
                /* Box-model */
                width: 45vw; /// 144/320 = 0.45
              }
            }

            &--title {
              /* Box-model */
              margin-bottom: 2.5vw; /// 8/320 = 0.025
            }

            &--slogan {
              /* Box-model */
              margin-bottom: 6.3vw; /// 20/320 = 0.0625
            }

            &--description {
              /* Box-model */
              margin-bottom: 0;

              /* Typography */
              font-size: 5vw; /// 16/320 = 0.05
            }
          }

          &__impression {
            /* Box-model */
            width: 165.6vw; /// 530/320 = 1.65625
            height: 99.4vw; /// 318/320 = 0.99375

            &--image {
              /* Positioning */
              left: 0;

              & img {
                /* Box-model */
                width: 100%;
              }
            }

            &--line {
              /* Positioning */
              top: 50%;

              /* Box-model */
              width: 100vw;

              /* Visual */
              transform: translateY(-50%);

              & img {
                /* Box-model */
                width: 100%;
              }
            }
          }
        }
      }

      &__incident-management {
        /* Box-model */
        margin-bottom: 18.6vw; /// 60/320 = 0.1875

        & .incident-management {
          /* Box-model */
          display: flex;
          padding: 0;

          /* Flexible Box */
          flex-direction: column;

          &__feature {
            /* Box-model */
            width: 90vw; // 288/320 = 0.9
            padding: 0;
            margin: 0 auto;

            &--icon {
              /* Box-model */
              margin-bottom: 7.5vw; /// 24/320 = 0.075

              & > img {
                /* Box-model */
                width: 45vw; /// 144/320 = 0.45
              }
            }

            &--type-title {
              font-size: 5.6vw; /// 18/320 = 0.05625
            }

            &--title {
              /* Box-model */
              margin-bottom: 3.1vw; /// 10/320 = 0.03125
            }

            &--slogan {
              /* Box-model */
              margin-bottom: 6.3vw; /// 20/320 = 0.0625
            }

            &--description {
              /* Box-model */
              margin-bottom: 6.3vw; /// 20/320 = 0.0625

              /* Typography */
              font-size: 5vw; /// 16/320 = 0.05
            }

            &--red-line {
              /* Box-model */
              width: 83.8vw; /// 268/320 = 0.8375
              margin: 0 0 6.3vw; /// 20/320 = 0.0625
            }

            &--type-title {
              /* Box-model */
              margin-bottom: 3.1vw; /// 10/320 = 0.03125
            }

            &--type-category {
              /* Box-model */
              height: 42.8vw; /// 137/320 = 0.428125

              /* Typography */
              font-size: 3.8vw; /// 12/320 = 0.0375
              letter-spacing: 0px;
            }
          }

          &__impression {
            /* Positioning */
            position: relative;
            top: -15vw; /// 48/320 = 0.15

            & img {
              width: 100vw;
            }
          }
        }
      }

      &__live-broadcasting {
        /* Box-model */
        margin: 0 0 50vw; /// 160/320 = 0.5

        & .live-broadcasting {
          &__feature {
            /* Box-model */
            width: 90vw; /// 288/320 = 0.9
            padding: 0;
            margin: 0 auto;

            &--icon {
              /* Box-model */
              margin-bottom: 7.3vw; /// 23.5/320 = 0.0734375

              & > img {
                /* Box-model */
                width: 45vw; /// 144/320 = 0.45
              }
            }

            &--title {
              /* Box-model */
              margin-bottom: 3.1vw; /// 10/320 = 0.03125
            }

            &--slogan {
              /* Box-model */
              margin-bottom: 6.3vw; /// 20/320 = 0.0625
            }

            &--description {
              /* Typography */
              font-size: 5vw; /// 16/320 = 0.05
              letter-spacing: 0px;
            }
          }

          &__impression {
            /* Positioning */
            position: relative;

            /* Box-model */
            width: 100vw;
            height: 84.4vw; /// 270/320 = 0.84375
            margin-bottom: 6.3vw; /// 20/320 = 0.0625
            overflow: hidden;

            & > img {
              /* Positioning */
              position: relative;
              top: -12.5vw; /// 40/320 = 0.125

              /* Box-model */
              width: 100%;
            }
          }

          &__x1000 {
            /* Box-model */
            width: 90vw; /// 288/320 = 0.9
            padding: 0;
            margin: 0 auto;

            & img {
              width: 100%;
            }
          }
        }
      }

      &__video-archive {
        /* Box-model */
        margin: 0;

        & .video-archive {
          /* Box-model */
          display: flex;

          /* Flexible Box */
          flex-direction: column-reverse;

          &__impression {
            /* Positioning */
            position: relative;
            top: 0;
            left: 12%;

            /* Box-model */
            width: 100vw;

            /* Visual */
            transform: translateX(-50%);

            & img {
              /* Box-model */
              width: 150%;
            }
          }

          &__feature {
            /* Box-model */
            width: 90vw; /// 288/320 = 0.9
            padding: 0;
            margin: 0 auto;

            &--icon {
              /* Box-model */
              width: 45vw; /// 144/320 = 0.45
              margin-bottom: 7.3vw; /// 23.5/320 = 0.0734375

              & > img {
                /* Box-model */
                width: 100%;
              }
            }

            &--title {
              /* Box-model */
              margin-bottom: 3.1vw; /// 10/320 = 0.03125
            }

            &--slogan {
              /* Box-model */
              margin-bottom: 6.25vw; /// 20/320 = 0.0625
            }

            &--description {
              /* Box-model */
              margin: 0 0 6.9vw; /// 22/320 = 0.06875

              /* Typography */
              font-size: 5vw; /// 16/320 = 0.05
              letter-spacing: 0px;
            }

            &--format {
              /* Typography */
              font-size: 3.8vw; /// 12/320 = 0.0375
              line-height: 6.25vw; /// 20/320 = 0.0625
            }
          }
        }
      }

      &__built-in-services {
        /* Box-model */
        padding: 0;
        margin-bottom: 56.9vw; /// 182/320 = 0.56875

        & .built-in {
          &__title {
            /* Box-model */
            margin: 0 0 15.9vw; /// 51/320 = 0.159375
          }
  
          &__container {
            /* Box-model */
            width: 100vw;
            padding: 0;

            /* Visual */
            border: none;

            &--image {
              & img {
                /* Positioning */
                position: relative;
                left: 50%;

                /* Box-model */
                width: 90vw; /// 288/320 = 0.9

                /* Visual */
                transform: translateX(-50%);
              }
            }

            &--text {
              /* Box-model */
              display: none;
            }
          }
        }
      }
    }
    & .key-features-2 {
      &__content-box {
        /* Box-model */
        display: none;
      }

      &__title {
        /* Box-model */
        margin-bottom: 7.8vw; /// 25/320 = 0.078125
      }

      &__image-box {
        display: none;

        &:nth-child(4n + 4) {
          /* Box-model */
          display: none;
        }
  
        &:nth-child(2n + 6) {
          /* Box-model */
          margin: 0;
        }
      }

      & .carousel__container {
        /* Box-model */
        display: block;
      }
    }

    & .download {
      /* Box-model */
      height: unset;
      margin-bottom: 15vw; /// 48/320 = 0.15
    }

    & .element-position {
      /* Positioning */
      position: relative;
      top: -25vw;
    }
  }
}
