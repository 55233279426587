@import "../../styles/style";
$line-height-description: 1.5;
$keyFeaturesRedCheck: "../../assets/images/faceta/key_features_red_check.svg";

.faceta {
  & .what-is-faceta {
    /* Positioning */
    z-index: z-index(contents);

    /* Box-model */
    width: 100%;
    margin-top: 1045px;

    &__content {
      /* Box-model */
      display: flex;
      height: 380px;
      margin: 0 112px 85px;
    }

    &__content-box {
      /* Box-model */
      padding: 0 56px 0 112px;

      /* Flexible Box */
      flex: 1;

      &--title {
        /* Box-model */
        margin-bottom: 30px;

        /* Typography */
        color: $blue-faceta;
      }

      &--content {
        /* Typography */
        line-height: $line-height-description;
        color: $black-text-clio;
        white-space: pre-line;
      }
    }

    &__logo-box {
      /* Box-model */
      display: flex;
      margin-right: 112px;

      /* Flexible Box */
      flex: 1;
      justify-content: center;

      &--logo {
        /* Box-model */
        margin-top: 116px;
      }
    }
  }

  & .how-it-works {
    &__content {
      /* Box-model */
      display: flex;
      width: 1366px;
      margin: 0 auto 85px;

      /* Flexible Box */
      justify-content: center;

      & > video {
        /* Box-model */
        width: 1280px;
        height: 720px;
      }
    }
  }

  & .key-features {
    /* Box-model */
    width: 100%;
    padding-top: 100px;
    padding-bottom: 192px;
    margin-top: 98px;

    /* Typography */
    color: $white-100;

    &__overview {
      /* Box-model */
      margin: 85px 224px;

      & .overview {
        /* Box-model */
        display: flex;

        /* Flexible Box */
        justify-content: space-between;

        &__item {
          /* Box-model */
          display: flex;
          width: 295px;
          height: 370px;

          /* Flexible Box */
          flex-direction: column;
          align-items: center;

          &--icon {
            /* Box-model */
            margin-top: 49px;
            margin-bottom: 34px;
          }

          &--title {
            /* Box-model */
            margin-bottom: 30px;

            /* Typography */
            color: $red-text;
            color: $white-100;
            text-align: center;

            &-normal {
              /* Positioning */
              position: relative;

              /* Typography */
              font-family: arisan-font-normal;

              &::before {
                /* Positioning */
                position: relative;
                top: 5px;
                left: 2px;

                /* Visual */
                content: url($keyFeaturesRedCheck);
              }
            }
          }

          &--content {
            /* Typography */
            line-height: $line-height-description;
            text-align: center;
          }

          &:nth-child(1) p {
            /* Box-model */
            width: 230px;
          }
          &:nth-child(2) p {
            /* Box-model */
            width: 228px;
          }
          &:nth-child(3) p {
            /* Box-model */
            width: 234px;
          }
        }
      }
    }
  }

  & .applications {
    /* Box-model */
    width: 100%;
    padding-bottom: 149px;

    &__content-box {
      /* Box-model */
      margin: 0 224px;

      & .applications-features {
        & + .applications-features {
          /* Box-model */
          margin-top: 100px;
        }

        &__main-content {
          /* Box-model */
          display: flex;
          width: 100%;
          margin-bottom: 88px;
        }

        &__icon-box {
          /* Positioning */
          position: relative;
          left: -32px;

          /* Box-model */
          width: 195px;

          /* Flexible Box */
          flex: 1;

          &--icon {
            /* Box-model */
            width: 92px;

            & img {
              /* Box-model */
              width: 100%;
            }
          }

          &--number {
            /* Positioning */
            position: absolute;
            right: 0;
            bottom: 0;

            /* Box-model */
            width: 32px;

            & img {
              /* Box-model */
              width: 100%;
            }
          }
        }

        &__content-box {
          /* Box-model */
          display: flex;
          padding-left: 30px;

          /* Flexible Box */
          flex: 3;
          flex-direction: column;
          justify-content: space-between;

          /* Visual */
          border-left: 2.5px solid $blue-faceta-border;

          &--title {
            /*Typography */
            line-height: $line-height-description;
            color: $blue-faceta;
          }

          &--content {
            /* Typography */
            line-height: 1.3;
            color: $black-text;
            white-space: pre-line;
          }
        }

        &__available-locations-container {
          & .available-locations {
            /* Box-model */
            display: flex;
            margin-top: 53px;

            /* Flexible Box */
            justify-content: flex-end;

            &__container {
              /* Box-model */
              display: flex;
              width: 75%;

              &--icon {
                /* Box-model */
                display: flex;
                width: 100px;
                height: 100px;

                /* Flexible Box */
                justify-content: center;
                align-items: center;
              }
            }

            &__content-box {
              /* Box-model */
              display: flex;
              margin-left: 20px;

              /* Flexible Box */
              flex-direction: column;
              justify-content: space-around;

              &--title {
                /* Typography */
                color: $blue-faceta;
              }

              &--content {
                /* Typography */
                line-height: $line-height-description;
                color: $black-text-clio;
                white-space: pre-line;
              }
            }
          }
        }
      }
    }

    &__images-box {
      /* Box-model */
      display: flex;

      /* Flexible Box */
      justify-content: space-between;

      &:nth-child(3) {
        /* Box-model */
        height: 1016px;
        margin-bottom: 86px;

        & > .applications__images-box--image:first-child {
          /* Flexible Box */
          align-self: flex-end;
        }
      }

      &:nth-child(5) {
        /* Box-model */
        display: flex;
        margin: 85px 0;
        
        /* Flexible Box */
        justify-content: flex-end;
      }
    }
  }

  & .download {
    /* Box-model */
    height: 86px;
    margin-bottom: 86px;
  }
}

.mobile-break {
  /* Box-model */
  display: block;
}

.desktop-break {
  /* Box-model */
  display: none;
}

@media (max-width: 1365px) {
  .faceta {
    & .what-is-faceta {
      /* Box-model */
      width: 90vw; /// 288/320 = 0.9
      margin-top: 320vw; /// 1024/320 = 3.2

      &__content {
        /* Box-model */
        margin: 0;
        height: unset;

        /* Flexible Box */
        flex-direction: column-reverse;
        align-items: center;
      }

      &__content-box {
        /* Box-model */
        padding: 0;

        &--title {
          /* Box-model */
          margin-bottom: 0;

          /* Typography */
          font-size: 10.3vw; /// 33/320 = 0.103125
        }

        &--content {
          /* Box-model */
          margin-top: 2.7vw; /// 8.5/320 = 0.0265625

          /* Typography */
          font-size: 5vw; /// 16/320 = 0.05
          letter-spacing: 0;
        }
      }

      &__logo-box {
        /* Box-model */
        margin-right: 0;
        margin-bottom: 18.8vw; /// 60/320 = 0.1875

        &--logo {
          /* Box-model */
          margin-top: 0;

          & img {
            /* Box-model */
            width: 62.5vw; /// 200/320 = 0.625
          }
        }
      }
    }

    & .how-it-works {
      /* Box-model */
      margin-top: 28.9vw; /// 92.5/320 = 0.2890625

      &__content {
        /* Box-model */
        width: 100vw;
        margin: 14.4vw auto 0; /// 46/320 =0.14375

        & > video {
          /* Box-model */
          width: 90vw; /// 288/320 = 0.9
          height: 50vw; /// 160/320 = 0.5
        }
      }
    }

    & .key-features {
      /* Box-model */
      width: 100vw;
      padding-top: 0;
      padding-bottom: 0;
      margin-top: 50.8vw;

      &__overview {
        /* Box-model */
        margin: 0;

        & .overview {
          /* Box-model */
          margin-top: 30.5vw; /// 97.5/320 = 0.3046875

          /* Flexible Box */
          flex-direction: column;
          align-items: center;

          &__item {
            /* Box-model */
            width: 71.9vw; /// 230/320 = 0.71875
            height: 88.1vw; /// 282/320 = 0.88125

            &--icon {
              /* Box-model */
              margin-top: 0;
              margin-bottom: 0;

              & img {
                /* Box-model */
                width: 39.7vw; /// 127/320 = 0.396875
              }
            }

            &--title {
              /* Box-model */
              margin-top: 5vw; /// 16/320 = 0.05
              margin-bottom: 0;

              /* Typography */
              font-size: 5.6vw; /// 18/320 = 0.05625

              &-normal {
                &::before {
                  /* Positioning */
                  top: 1.6vw; /// 5/320 = 0.015625
                  left: 0.6vw; /// 2/320 = 0.00625

                  /* Box-model */
                  display: inline-block;
                  width: 11.3vw; /// 36/320 = 0.1125
                  height: 13.4vw; /// 43/320 = 0.134375

                  /* Visual */
                  content: "";
                  background-image: url($keyFeaturesRedCheck);
                  background-size: 13.4vw 11.3vw;
                  background-repeat: no-repeat;
                }
              }
            }

            &--content {
              /* Box-model */
              margin-top: 8.8vw; /// 28/320 = 0.0875
            }

            & p {
              /* Typography */
              font-size: 5vw; /// 16/320 = 0.05
              line-height: 7.8vw; /// 25/320 = 0.078125
              letter-spacing: 0;
            }

            &:nth-child(1) p {
              /* Box-model */
              width: unset;
            }
            &:nth-child(2) {
              /* Box-model */
              margin-top: 27.5vw; /// 88/320 = 0.275

              & p {
                /* Box-model */
                width: unset;
              }
            }
            &:nth-child(3) {
              /* Box-model */
              margin-top: 27.5vw; /// 88/320 = 0.275
              & p {
                /* Box-model */
                width: unset;
              }
            }
          }
        }
      }
    }

    & .applications {
      /* Box-model */
      margin-top: 55vw; /// 176/320 = 0.55
      padding-bottom: 30vw; /// 96/320 = 0.3

      &__content-box {
        /* Box-model */
        margin: 0;

        & .applications-features {
          /* Box-model */
          margin-top: 7.6vw; /// 24.5/320 = 0.0765625

          & + .applications-features {
            /* Box-model */
            margin-top: 0;
          }

          &__main-content {
            /* Box-model */
            margin-bottom: 0;

            /* Flexible Box */
            flex-direction: column;
            align-items: center;
          }

          &__icon-box {
            /* Positioning */
            left: 0;
            
            /* Box-model */
            width: 90vw; /// 288/320 = 0.9
            height: 59.4vw; /// 190/320 = 0.59375

            /* Flexible Box */
            flex: auto;
  
            &--icon {
              /* Box-model */
              width: 41.3vw; /// 132/320 = 0.4125
            }
  
            &--number {
              /* Box-model */
              width: 8.1vw; /// 26/320 = 0.08125
            }
          }

          &__content-box {
            /* Box-model */
            width: 90vw; /// 288/320 = 0.9
            padding-left: 0;
            margin-top: 14.3vw; /// 45.8/320 = 0.143125
    
            /* Visual */
            border-left: none;

            &--title {
              /* Typography */
              font-size: 6.9vw; /// 22/320 = 0.06875
            }

            &--content {
              /* Box-model */
              margin-top: 6.6vw; /// 21/320 = 0.065625

              /* Typography */
              font-size: 5.6vw; /// 18/320 = 0.05625
              letter-spacing: -0.06vw;
            }
          }
    
          &__available-locations-container {
            /* Box-model */
            width: 90vw; /// 288/320 = 0.9
            margin: 14.1vw auto 0; /// 45/320 = 0.140625

            & .available-locations {
              /* Box-model */
              margin-top: 6.9vw; /// 22/320 = 0.06875

              /* Flexible Box */
              justify-content: normal;
    
              &__container {
                /* Box-model */
                width: 100%;
      
                &--icon {
                  /* Box-model */
                  width: unset;
                  height: unset;

                  & img {
                    /* Box-model */
                    width: 12.5vw; /// 40/320 = 0.125
                  }
                }
              }
      
              &__content-box {
                /* Box-model */
                margin-left: 9.3vw; /// 30/320 = 0.09375

                &--title {
                  /* Typography */
                  font-size: 5.6vw; /// 18/320 = 0.05625
                }

                &--content {
                  /* Box-model */
                  margin-top: 1.3vw; /// 4/320 = 0.0125

                  /* Typography */
                  font-size: 5vw; /// 16/320 = 0.05
                  line-height: 7.8vw; /// 25/320 = 0.078125
                }
              }
            }
          }

          &:nth-child(2) {
            /* Box-model */
            margin-top: 28.1vw /// 90/320 = 0.28125
          }
        }
      }

      &__images-box {
        /* Box-model */
        display: flex;
        width: 100vw;
        height: unset;

        /* Flexible Box */
        flex-direction: column-reverse;
        align-items: center;

        &--image {
          /* Positioning */
          position: relative;

          /* Box-model */
          width: 85vw; /// 272/320 = 0.85

          & img {
            /* Box-model */
            width: 100%;
          }
        }

        &:nth-child(3) {
          /* Box-model */
          height: unset;
          margin: 14.4vw 0 15.6vw; /// 40/320 = 0.125 50/320 = 0.15625

          & > .applications__images-box--image:first-child {
            /* Flexible Box */
            align-self: auto;
          }
        }

        &:nth-child(5) {
          /* Box-model */
          margin: 14.4vw 0 15.6vw; /// 40/320 = 0.125 50/320 = 0.15625
        }
      }
    }

    & .download {
      /* Box-model */
      height: unset;
      margin-bottom: 15vw; /// 48/320 = 0.15
    }

    & .element-position {
      /* Positioning */
      position: relative;
      top: -25vw;
    }
  }

  .image--right {
    /* Positioning */
    right: -7.5vw; /// 24/320 = 0.075
  }

  .image--left {
    /* Positioning */
    left: -7.5vw; /// 24/320 = 0.075
  }
}
