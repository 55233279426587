@import "../../styles/style";

.clio-background {
  /* Box-model */
  width: 4366px;
  z-index: z-index(background);

  /* Visual */
  background-color: $white-100;

  &__header {
    /* Positoning */
    position: relative;

    /* Box-model */
    height: 851px;
    margin-bottom: 658px;

    &--red {
      /* Positioning */
      z-index: z-index(background-below);

      /* Box-model */
      /* right end height */
      width: 100%;
      height: 669px;

      /* Visual */
      background: $red-background;
      transform: skewY(7.9deg);
      transform-origin: 50% 0%;
    }

    &--image {
      /* Positioning */
      position: absolute;
      /* Align the middle to the height */
      top: -356px;

      /* Box-model */
      /* right end height */
      width: 100%;
      height: 1111px;

      /* Visual */
      background: $black-clio-header-background;
      transform: skewY(-7.96deg);
      transform-origin: 50% 0%;
    }
  }

  &__how-it-works {
    /* Positioning */
    position: relative;
    z-index: z-index(background-below);

    /* Box-model */
    width: 100%;
    height: 1149px;

    &--block {
      /* Box-model */
      height: 100%;
      width: 100%;

      /* Visual */
      background: $gray-clio-background;
      transform: skewY(-8deg);
      transform-origin: 50% 0%;
    }
  }

  &__key-features {
    /* Positioning */
    position: relative;

    /* Box-model */
    width: 100%;
    height: 5685px;

    &--block {
      /* Box-model */
      width: 100%;
      height: 4289px;

      /* Visual */
      background: $black-100;
      transform: skewY(-8deg);
      transform-origin: 50% 0%;
    }

    &--red {
      /** Using position: absolute and margin auto to move position to the center
      and use right: fix to need position  */
      /* Position */
      position: absolute;
      top: 11px;
      left: 0;
      right: 490px;
      bottom: 0;
      z-index: z-index(background-below);

      /* Box-model */
      height: 100px;
      width: 527px;
      margin: 0 auto 0;

      /* Visual */
      background: $red-background;
      transform: skewY(-8deg);
      transform-origin: 50% 0%;
    }

    &--video-archive {
      /* Positioning */
      position: relative;
      top: -1px;

      /* Box-model */
      width: 100%;
      height: 1480px;

      /* Visual */
      background: $black-key-features-background;
      transform: skewY(-8deg);
      transform-origin: 50% 0%;
    }

    &--bottom {
      /* Positioning */
      position: relative;
      top: -313px;
      z-index: z-index(background-below);

      /* Box-model */
      width: 100%;
      height: 390px;

      /* Visual */
      background: $gray-clio-background;
    }
  }

  &__features-detail {
    /* Box-model */
    width: 100%;
    height: 5090px;
  }
}

@media (max-width: 1365px) {
  .clio-background {
    /* Box-model */
    width: 100vw;
    
    &__header {
      /* Box-model */
      width: 100vw;
      height: 268.4vw; /// 859/320 = 2.684375
      margin-bottom: 237.5vw; /// 760/320 = 2.375
      overflow: hidden;

      &--red {
        /* Box-model */
        width: 100vw;
        height: 268.1vw; /// 858/320 = 2.68125
        
        /* Visual */
        transform-origin: right bottom;
      }

      &--image {
        /* Positioning */
        top: 0;

        /* Box-model */
        width: 100vw;
        height: 268.4vw; /// 859/320 = 2.684375

        /* Visual */
        transform-origin: left bottom;
      }
    }

    &__how-it-works {
      /* Box-model */
      width: 100vw;
      height: 289.6vw; /// 926.7/320 = 2.8959375

      &--block {
        /* Visual */
        transform-origin: top left;
      }
    }

    &__key-features {
      /* Box-model */
      width: 100vw;
      height: unset;

      &--red {
        /* Positioning */
        top: -1.9vw; /// 6/320 = 0.01875

        /* Box-model */
        width: 50vw; /// 160/320 = 0.5
        height: 9.4vw; /// 30/320 = 0.09375
        margin: 0;

        /* Visual */
        transform-origin: top left;
      }

      &--block {
        /* Box-model */
        height: 275vw; /// 882/320 = 2.75625

        /* Visual */
        transform-origin: top left;
      }

      &--incident-management {
        /* Positioning */
        /// overlap with the above component to cover the gap between div
        position: relative;
        top: -1px;

        /* Box-model */
        width: 100vw;
        height: 393vw; /// 1260/320 = 3.9375

        /* Visual */
        background: $black-key-features-background;
        transform: skewY(-8deg);
        transform-origin: top left;
      }

      &--live-broadcasting {
        /* Positioning */
        position: relative;
        top: -2px;
  
        /* Box-model */
        width: 100vw;
        height: 318vw; /// 1020/320 = 3.1875

        /* Visual */
        background: $black-100;
        transform: skewY(-8deg);
        transform-origin: top left;
      }

      &--video-archive {
        /* Positioning */
        top: -3px;

        /* Box-model */
        width: 100vw;
        height: 450vw; /// 1440/320 = 45

        /* Visual */
        transform-origin: top left;
      }

      &--bottom {
        /* Box-model */
        height: 0;
      }
    }
    
    &__features-detail {
      /* Box-model */
      width: 100vw;
      height: 270vw;
    }
  }
}
