@import '../../styles/style';

.about-us-header {
  &__container {
    /* Positioning */
    position: relative;

    /* Box-model */
    width: 1366px;
    height: 388px;

    &__red {
      /* Positioning */
      position: relative;

      /* Box-model */
      width: 1366px;
      height: 388px;
  
      /* Visual */
      background: $red-background;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 50%);
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 50%);
    }
  
    &__building {
      /* Positioning */
      position: absolute;
      top: 0;
      z-index: z-index(background-below);

      /* Box-model */
      width: 1366px;
      height: 364px;

      /* Visual */
      background: $about-us-building-extend-color;
      clip-path: polygon(0 0, 100% 0, 100% 47.25%, 0 100%); /// 0.47252747252
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 47.25%, 0 100%);
    }

    &__title {
      /* Positioning */
      position: absolute;
      top: 156px;
      left: 50%;
      z-index: z-index(header-title);

      /* Visual */
      transform: translateX(-50%);

      /* Typography */
      font-size: 30px;
      letter-spacing: 0.27px;
      color: $white-100;
    }
  }

  &__extends {
    &__left {
      /* Positioning */
      position: absolute;
      right: calc(1366px + (100vw - 1366px) / 2 - 5px);

      &--red {
        /* Positioning */
        position: relative;

        /* Box-model */
        width: 1395px;
        height: 194px;

        /* Visual */
        background: $red-background;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 0);
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 0);
      }

      &--building {
        /* Positioning */
        position: absolute;
        top: 0;
        z-index: z-index(background-below);

        /* Box-model */
        width: 1395px;
        height: 560px;

        /* Visual */
        background: $about-us-building-extend-color;
        clip-path: polygon(0 0, 100% 0, 100% 65%, 0 100%); /// 364/360 = 0.65
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 65%, 0 100%);
      }
    }

    &__right {
      /* Positioning */
      position: absolute;
      left: calc(1366px + (100vw - 1366px) / 2 - 5px);

      /* Box-model */
      width: 1395px;
      height: 584px;

      /* Visual */
      background: $red-background;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 66.4%); /// 388/584 = 0.66438356164
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 66.4%);
    }
  }
}

@media (max-width: 1365px) {
  .about-us-header {
    &__container {
      /* Box-model */
      width: 100vw;
      height: 54.7vw; /// 175/320 = 0.546875

      &__red {
        /* Box-model */
        width: 100vw;
        height: 54.7vw; /// 175/320 = 0.546875
        
        /* Visual */
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 74.28%); /// 130/175 = 0.74285714285
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 74.28%);
      }

      &__building {
        /* Box-model */
        width: 100vw;
        height: 51.6vw; /// 165/320 = 0.515625

        /* Visual */
        clip-path: polygon(0 0, 100% 0, 100% 72.72%, 0 100%); /// 120/165 = 0.72727272727
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 72.72%, 0 100%);

        & img {
          /* Box-model */
          width: 100%;
        }
      }

      &__title {
        /* Positioning */
        top: 29.1vw; /// 93/320 = 0.290625

        /* Typography */
        font-size: 6.9vw; /// 22/320 = 0.06875
      }
    }

    &__extends {
      /* Box-model */
      display: none;
    }
  }
}
