@import "../../styles/style";

@media (max-width: 1365px) {
  .carousel {
    &__container {
      /* Positioning */
      position: relative;

      /* Box-model */
      display: flex;
      width: 100vw; /// 320/320 = 1

      /* Flexible Box */
      flex-direction: column;
      align-items: center;

      &--control {
        /* Box-model */
        height: 100vw; /// 320/320 = 1

        .carousel__slide-container {
          /* positioning */
          position: absolute;
          top: 29.1vw; /// 93/320 = 0.290625

          /* Box-model */
          display: flex;
          width: 100%;
          height: 100vw;

          /* Flexible Box */
          flex-direction: column;
          align-items: center;
        }

        .carousel__slide-image {
          /* Box-model */
          width: 29.4vw; /// 94/320 = 0.29375
          height: 26.6vw; /// 85/320 = 0.265625
          margin-bottom: 15vw; /// 48/320 = 0.15
        }

        .carousel__name {
          /* Typography */
          line-height: 9.4vw; /// 30/320 = 0.09375
          font-size: 6.6vw; /// 21/320 = 0.065625
        }

        .carousel__control-container {
          /* positioning */
          position: absolute;
          top: 38.8vw; /// 124/320 = 0.3875

          /* Box-model */
          width: 90.3vw; /// 289/320 = 0.903125
          height: 12.2vw; /// 39/320 = 0.121875
          display: flex;

          /* Flexible Box */
          justify-content: space-between;

          button {
            /* Typography */
            cursor: pointer;
          }
        }
      }

      &--indicator {
        /* Positioning */
        position: relative;

        .carousel__slide-container {
          /* positioning */
          position: relative;

          /* Box-model */
          display: flex;
          width: 100%;
          margin-bottom: 8.5vw; /// 27.3/320 = 0.0853125

          /* Flexible Box */
          align-items: center;
          justify-content: center;
        }

        .carousel__slide-image {
          /* Box-model */
          width: 30.6vw; /// 98/320 = 0.30625
          height: 30.6vw; /// 98/320 = 0.30625
          margin-right: 5.9vw; /// 19/320 = 0.059375
        }

        .carousel__name {
          /* Typography */
          font-size: 5.6vw; /// 18/320 = 0.05625
          line-height: 7.8vw; /// 25/320 = 0.078125
          font-weight: 500;
          color: $black-text;
        }

        .carousel__type {
          /* Typography */
          font-size: 5.6vw; /// 18/320 = 0.05625
          line-height: 7.8vw; /// 25/320 = 0.078125
          color: $black-text-clio;
        }

        .carousel__indicator-container {
          /* positioning */
          position: relative;

          /* Box-model */
          display: flex;

          /* Flexible Box */
          justify-content: center;

          button {
            /* Typography */
            cursor: pointer;
          }

          button + button {
            margin-left: 2.2vw; /// 7/320 = 2.1875
          }
        }

        .carousel__indicator {
          /* Box-model */
          width: 3.8vw; /// 12/320 = 0.0375
          height: 3.8vw;

          /* Visual */
          border: 1px solid $gray-content;
          border-radius: 50%;

          &--active {
            /* Visual */
            background: $gray-content;
          }
        }
      }
    }

    &__slide-container.is-key-features {
      /* Box-model */
      display: flex;

      /* Flexible Box */
      flex-direction: column;
    }

    &__slide-image {
      &.is-key-features {
        /* Box-model */
        width: 25vw; /// 80/320 = 0.25
        height: 45vw; /// 144/320 = 0.45
      }

      img {
        /* Box-model */
        width: 100%;
      }
    }

    &__slide__features {
      /* Positioning */
      position: relative;

      /* Box-model */
      display: flex;
      width: 90vw; /// 288/320 = 0.9
      margin-top: 7.7vw; /// 24.5/320 = 0.0765625

      /* Flexible Box */
      flex-direction: column;
      
      &__number {
        /* Positioning */
        position: absolute;
        right: 0;

        /* Box-model */
        width: 5vw; /// 16/320 = 0.05
        height: 15vw; /// 48/320 = 0.15
        padding-right: 4.4vw; /// 14/320 = 0.04375

        /* Visual */
        border-right: 1px solid $red-background;

        & img {
          /* Positioning */
          position: absolute;
          bottom: 0;

          /* Box-model */
          width: 5vw; /// 16/320 = 0.05
        }
      }

      &__text {
        /* Box-model */
        margin-top: 22.5vw; /// 72/320 = 0.225

        &--title {
          /* Box-model */
          height: 15vw; /// 48/320 = 0.15

          /* Typography */
          font-size: 6.9vw; /// 22/320 = 0.06875
          color: $red-text;
        }

        &--description {
          /* Box-model */
          height: 60vw; /// 192/320 = 0.6

          /* Typography */
          font-size: 5vw; /// 16/320 = 0.05
          line-height: 7.8vw; /// 25/320 = 0.078125
          letter-spacing: 0.1px;
          color: $black-text-clio;

          & p {
            font-size: 3.8vw; /// 12/320 = 0.0375
          }
        }
      }
    }
  }
}
