@import "../../styles/_style";

.problem {
  /* Box-model */
  display: flex;
  width: 1283px;
  height: 587px;

  /* Flexible Box */
  justify-content: space-evenly;

  &:nth-child(3) {
    /* Flexible Box */
    flex-direction: row-reverse;
  }

  &__image-container {
    /* Box-model */
    width: 525px;
    height: 540px;

    img {
      /* Box-model */
      width: 100%;
      height: 100%;
    }
  }

  &__text-container {
    /* Box-model */
    display: flex;
    width: 509px;

    /* Flexible Box */
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__description {
    /* Box-model */
    display: flex;
    margin-bottom: 35px;

    /* Flexible Box */
    flex-wrap: wrap;
    justify-content: center;

    .description {
      &__normal {
        /* Typography */
        color: $gray-about-us-paragraph;
      }

      &__highlight {
        /* Positioning */
        position: relative;

        /* Typography */
        color: $white-100;
      }

      &__strikethrough {
        /* Positioning */
        position: absolute;
        top: 50%;

        /* Box-model */
        width: 100%;

        /* Visual */
        border-bottom: 5px solid $red-text;
        animation: strike-through 4s infinite;
      }

      @keyframes strike-through {
        0% {
          width: 0%;
        }

        20% {
          width: 0%;
        }

        80% {
          width: 100%
        }

        100% {
          width: 100%
        }
      }
    }
  }

  &__solution {
    /* Box-model */
    display: flex;
    width: 434px;
    height: 166px;

    /* Flexible Box */
    justify-content: center;
    align-items: center;

    /* visual */
    border-top: 1px solid $gray-cases-type;
    border-bottom: 1px solid $gray-cases-type;
  }

  &__solution-image {
    /* Box-model */
    width: 99px;
    height: 64px;
    margin-right: 45px;

    img {
      /* Box-model */
      width: 100%;
      height: 100%;
    }
  }

  &__solution-name {
    /* Box-model */
    width: 269px;

    /* Typography */
    color: $red-text;
    line-height: 26px;
    font-size: 21px;
  }
}

@media (max-width: 1365px) {
  .problem {
    /* Box-model */
    width: 100%;
    height: 166.9vw; /// 534/320 = 1.66875

    /* Flexible Box */
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;

    &:nth-child(3) {
      /* Flexible Box */
      flex-direction: column;
    }

    &__text-container {
      /* Box-model */
      width: 100%;
    }

    &__image-container {
      /* Box-model */
      display: flex;
      width: 100%;
      height: 92.5vw; /// 296/320 = 0.925

      /* Flexible Box */
      justify-content: center;

      img {
        /* Box-model */
        width: 90%;
        height: 100%;
      }
    }

    &__description {
      /* Box-model */
      width: 87.5vw; /// 280/320 = 0.875
      margin-bottom: 2.8vw; /// 9/320 = 0.028125
    }

    &__solution {
      /* Box-model */
      width: 73.4vw; /// 235/320 = 0.734375
      height: 28.1vw; /// 90/320 = 0.28125
    }

    &__solution-image {
      /* Box-model */
      width: 17.2vw; /// 55/320 = 0.171875
      height: 11.3vw; /// 36/320 = 0.1125
      margin-right: 11.1vw; /// 35.6/320 = 0.11125

      img {
        /* Box-model */
        width: 100%;
        height: 100%;
      }
    }

    &__solution-name {
      /* Box-model */
      width: 39.1vw; /// 125/320 = 0.390625

      /* Typography */
      color: $red-text;
      line-height: 7.8vw; /// 25/320 = 0.078125
      font-size: 5.6vw; /// 18/320 = 0.05625
    }
  }
}
