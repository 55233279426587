.applications-section {
  /* Positioning */
  z-index: z-index(contents); /// 5

  /* Box-model */
  width: 1366px;
  height: 863px;

  &__title {
    /* Box-model */
    width: 100%;
    height: 181px;
    padding-top: 88px;
  }

  &__icons-container {
    /* Box-model */
    display: grid;
    width: 100%;
    height: 682px;

    /* Grid Box */
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 341px);

    /* Visual */
    border-top: 1px solid #EDEDED;
    border-left: 1px solid #EDEDED;
  }

  .carousel__container {
    /* Box-model */
    display: none;
  }
}

@media (max-width: 1365px) {
  .applications-section {
    /* Box-model */
    width: 100%;
    height: 133.8vw; /// 428/320 = 1.3375

    &__title {
      /* Box-model */
      width: 100%;
      height: 33.8vw; /// 108/320 = 0.3375
      padding-top: 15vw; /// 48/320 = 0.15
    }

    .carousel__container {
      /* Box-model */
      display: flex;
    }

    &__icons-container {
      /* Box-model */
      display: none;
    }
  }
}
