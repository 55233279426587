@import "../../styles/style";

.arisan-info {
  .cases-section {
    /* Positioning */
    position: relative;

    /* Box-model */
    width: 100%;
    height: 481px;

    &__title {
      /* Box-model */
      width: 100%;
      height: 181px;
      padding-top: 88px;
    }

    &.is-services {
      /* Box-model */
      height: 396px;

      .cases-section__title {
        /* Box-model */
        height: 96px;
        padding: 0px;
      }
    }

    &__content {
      /* Box-model */
      display: flex;
      width: 100%;
      height: 300px;

      /* Flexible Box */
      justify-content: space-around;
      align-items: center;
    }

    &__case-container {
      /* Box-model */
      display: flex;
      width: 355px;
      height: 140px;
    }

    &__image {
      /* Box-model */
      width: 140px;
      height: 140px;
      margin-right: 30px;

      img {
        /* Box-model */
        width: 100%;
        height: 100%;
      }
    }

    &__information {
      /* Box-model */
      display: flex;

      /* Flexible Box */
      flex-direction: column;
      justify-content: center;
    }

    &__name {
      /* Typography */
      color: $black-text;
    }

    &__type {
      /* Typography */
      color: $gray-cases-type;
      line-height: 25px;
    }

    .carousel__container {
      /* Box-model */
      display: none;
    }
  }
}

@media (max-width: 1365px) {
  .arisan-info {
    .cases-section {
      /* Box-model */
      height: 99.4vw; /// 318/320 = 0.99375

      &__title {
        /* Box-model */
        height: 33.4vw; /// 107/320 = 0.334375
        padding-top: 15vw; /// 48/320 = 0.15
        margin-bottom: 17.5vw;
      }

      &.is-services {
        /* Box-model */
        height: unset;
      }

      &__content {
        /* Box-model */
        display: none;
      }

      .carousel__container {
        /* Box-model */
        display: flex;
      }
    }
  }
}
