@import '../../styles/style';

.sub-nav {
  /* Positioning */
  position: absolute;
  left: 0;

  /* Box-model */
  display: none;
  width: 226px;

  & .disabled {
      /* Visual */
    display: none;
  }

  &__item {
    /* Box-model */
    line-height: 53px;

    &:hover {
      /* Visual */
      background: $black-100;

      & > a {
        /* Typography */
        color: $red-text;
      }
    }

    &.active {
      /* Visual */
      background: $black-100;
      
      & > a {
        /* Typography */
        color: $red-text;
      }
    }

    &--link {
      /* Box-model */
      display: block;
      padding: 0 33px;

      /* Typography */
      white-space: nowrap;

      /* Visual */
      color: $white-100-invisible;
    }

    &--link--visible {
      /* Box-model */
      display: block;
      padding: 0 33px;

      /* Typography */
      white-space: nowrap;

      /* Visual */
      color: $white-100;
      transition: 1s;
    }
  }
}
