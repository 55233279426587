@import "../../styles/style";

.services-background {
  /* Positioning */
  position: relative;
  z-index: z-index(background);

  /* Box-model */
  width: 4366px;
  margin-bottom: 306px;

  /* Visual */
  background-color: $white-100;

  &__product {
    /* Box-model */
    width: 100%;
    height: 3294px;
    margin-top: 1116px;

    &--block {
      /* Box-model */
      width: 100%;
      height: 100%;

      /* Visual */
      background: $black-100;
      transform: skewY(-8deg);
      transform-origin: 50% 0%;
    }

    &--bottom {
      /* Positioning */
      position: absolute;
      bottom: -306px;
      z-index: z-index(background-below);

      /* Box-model */
      width: 100%;
      height: 612px;

      /* Visual */
      background: $gray-arisan-info;
    }
  }
}

@media (max-width: 1365px) {
  .services-background {
    /* Box-model */
    width: 100vw;
    margin-bottom: 0;

    &__product {
      /* Box-model */
      height: 826vw;
      margin-top: calc(1103.5 / 320 * 100vw - 20vw);
    }
  }
}
