@import "../../styles/style";
.services {
  /* Positioning */ 
  z-index: z-index(contents);
  
  /* Box-model */
  display: flex;
  width: 100vw;
  margin:0 auto;

  /* Flexible Box */
  flex-direction: column;
  align-items: center;
}
