@import "../../styles/_style";

.arisan-title {
  /* Box-model */
  display: flex;
  margin-bottom: 85px;

  /* Flexible Box */
  flex-direction: column;
  align-items: center;

  &__text {
    /* Box model */
    margin-bottom: 12px;
  }

  &__underline {
    /* Box-model */
    width: 80px;

    /* Visual */
    border-bottom: 2px solid $red-text;
  }

  &.black {
    /* Typography */
    color: $black-text;
  }

  &.white {
    /* Typography */
    color: $white-100;
  }

  &.home {
    /* Box-model */
    margin-bottom: 0px;
  }
}

@media (max-width: 1365px) {
  .arisan-title {
    /* Box-model */
    margin-bottom: 0px;

    &__text {
      /* Box-model */
      margin-bottom: 1.6vw; /// 5/320 = 0.015625
    }

    &__underline {
      /* Box-model */
      width: 15.6vw; /// 50/320 = 0.15625

      /* Visual */
      border-bottom: 0.6vw solid $red-text; /// 2/320 = 0.00625
    }
  }
}
